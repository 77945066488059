import React, {
  createContext,
  type ForwardedRef,
  forwardRef,
  type ReactNode,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useMemo } from "react";
import { Virtuoso, type VirtuosoHandle } from "react-virtuoso";
import {
  type ColumnDef,
  createColumnHelper,
  type ExpandedState,
  type ExpandedStateList,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  type Header,
  type HeaderGroup,
  type OnChangeFn,
  type Row as ReactTableRow,
  type RowSelectionState,
  type SortingState,
  type Table,
  useReactTable,
} from "@tanstack/react-table";
import cn from "clsx";

import { useDeepMemo } from "../../hooks/use-deep-memo";
import { useEvent } from "../../hooks/use-event";
import { useKeydown } from "../../hooks/use-keydown";
import { useKeyup } from "../../hooks/use-keyup";
import { usePrevious } from "../../hooks/use-previous";
import { useResizeObserver } from "../../hooks/use-resize-observer";
import { debounce } from "../../utils/debounce";
import { dotObject } from "../../utils/dot-object";
import { getScrollableParent } from "../../utils/get-scrollable-parent";
import { is } from "../../utils/is";
import { suffixify } from "../../utils/suffixify";
import { EmptyState } from "../empty-state";
import { Flex } from "../flex";
import { Icon } from "../icon";
import { Loader } from "../loader";
import { Pagination } from "../pagination";
import { useProvider } from "../provider/provider-context";
import { Tooltip } from "../tooltip";
import { Wrapper } from "../wrapper";

import { TableAddonLeft } from "./table-addon-left";
import { TableCellAction } from "./table-cell-action";
import { TableColumn } from "./table-column";
import { DEFAULT_TABLE_CONTEXT, useTableContext } from "./table-context";
import { TableSelect } from "./table-select";
import type {
  Column,
  ContextType,
  CurriedToggleSelectedHandler,
  ExpandData,
  GroupColumn,
  MultipleSelectAddon,
  OnResizeHandler,
  Props,
  Ref,
  RenderColumn,
  RenderRowAddonHandler,
  Row,
  SelectedData,
  SingleSelectAddon,
  StatusState,
  UnknownData,
} from "./types";
import {
  diffInternalValueToExternalValue,
  flattenData,
  getColumnById,
  getColumnGridStyle,
  getColumnsWidths,
  getCommonStickyStyles,
  getLeafColumns,
  hasColumnAttr,
  isGroupColumn,
  isRenderColumn,
  mapExternalValueToInternalValue,
} from "./utils";
import styles from "./table.module.css";

const SET_DATA_STATE_TIMEOUT = 100;

const SCROLL_TIMEOUT = 200;

const TableContext = createContext<ContextType>({
  testId: "",
  select: {},
  tableId: "",
  renderRowAddon: () => null,
});

const EMPTY_DATA: UnknownData[] = [];

const EMPTY_COLUMNS: Column<UnknownData>[] = [];

const Table = <Data extends UnknownData & { data?: Data["data"] }>(
  {
    id,
    row = {},
    size = "md",
    data = EMPTY_DATA as Data[],
    sort = {},
    style,
    order = {},
    header = {},
    sticky = {},
    footer = {},
    select = {},
    expand = {},
    column = {},
    columns = EMPTY_COLUMNS,
    loading,
    bordered = true,
    maxHeight = "auto",
    className,
    visibility = {},
    emptyState = { title: "No data" },
    pagination,
    "data-testid": testId,
  }: Props<Data>,
  ref: ForwardedRef<Ref>
) => {
  const { virtualScroll: providerVirtualScroll } = useProvider();

  const internalId = useId();
  const tableId = id ?? internalId;

  const bodyRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<Ref>(null);
  const internalRef = useRef<Ref>(null);
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const shiftKeyRef = useRef(false);
  const placeholderRowRef = useRef<HTMLDivElement>(null);
  const lastSelectedRowRef = useRef<ReactTableRow<Data> | null>(null);

  /**
   * To be able to properly show empty state, we need to track wrapper width
   */
  const [wrapperWidth, setWrapperWidth] = useState(0);

  /**
   * These states are used when we use lazy expand on expand addon
   */
  const [status, setStatus] = useState<StatusState>([]);
  const [internalData, _setInternalData] = useState(data);

  const tableContext = useTableContext();

  /**
   * Since now we have TableProvider that handle all the configuration logic
   * we need to make sure that we are using the right context, so we need to
   * check if the id of the table is the same as the one from the context
   * if it's not the same we just ignore the context.
   */
  const {
    order: contextOrder,
    sticky: contextSticky,
    visibility: contextVisibility,
    setColumns,
    setStickyEnabled,
    setDescendantsIds,
  } = id
    ? id === tableContext.id
      ? tableContext
      : DEFAULT_TABLE_CONTEXT
    : tableContext;

  const setInternalData = useCallback<typeof _setInternalData>((nextData) => {
    _setInternalData((previousData) =>
      typeof nextData === "function" ? nextData(previousData) : nextData
    );
  }, []);

  const debouncedSetInternalData = useMemo(
    () => debounce(setInternalData, SET_DATA_STATE_TIMEOUT),
    [setInternalData]
  );

  /**
   * Memoized selectors for row addon
   */
  const rowRender = useMemo(() => row.render, [row.render]);
  const rowIsError = useMemo(() => row.isError, [row.isError]);
  const rowOnClick = useMemo(() => row.onClick, [row.onClick]);
  const rowIsLoading = useMemo(() => row.isLoading, [row.isLoading]);
  const rowIsVisible = useMemo(() => row.isVisible, [row.isVisible]);

  /**
   * Memoized selectors for select addon
   */
  const selectValue = useMemo(
    () =>
      select.value
        ? is.array(select.value)
          ? select.value
          : [select.value]
        : undefined,
    [select.value]
  );
  const selectOffset = useMemo(() => select.offset, [select.offset]);
  const selectOnChange = useMemo(() => select.onChange, [select.onChange]);
  const selectReference = useMemo(
    () => select.reference ?? "id",
    [select.reference]
  );
  const selectIsDisabled = useMemo(
    () => select.isDisabled,
    [select.isDisabled]
  );
  const selectIsSelectable = useMemo(
    () => select.isSelectable ?? (() => true),
    [select.isSelectable]
  );
  const selectPlacement = useMemo(() => select.placement, [select.placement]);
  const selectAutoSelectChild = (select as MultipleSelectAddon<Data>)
    .autoSelectChild;
  const selectMultiple = selectOnChange ? select.multiple !== false : undefined;

  /**
   * This is an edge case we need to handle to cover multiple table
   */
  const hasPlaceholderSelect = useMemo(
    () => columns.some((column) => column.id === "select"),
    [columns]
  );

  /**
   * Memoized selectors for expand addon
   */
  const expandValue = useMemo(() => expand.value, [expand.value]);
  const expandOnChange = useMemo(() => expand.onChange, [expand.onChange]);
  const expandOnChangeAll = useMemo(
    () => expand.onChangeAll,
    [expand.onChangeAll]
  );
  const expandReference = useMemo(
    () => expand.reference ?? "id",
    [expand.reference]
  );
  const expandGetLazyChildren = useMemo(
    () => expand.getLazyChildren,
    [expand.getLazyChildren]
  );

  /**
   * We only use internalData when we have expand addon with lazy children
   * otherwise we use the data prop to avoid rerendering issues
   */
  const enhancedData = expandGetLazyChildren ? internalData : data;
  const previousData = usePrevious(data, EMPTY_DATA as typeof data);

  const hasExpand = useMemo(
    () => enhancedData.some((item) => item.data !== undefined),
    [enhancedData]
  );

  /**
   * Memoized selectors for column addon
   */
  const columnOnResize = useMemo(() => column.onResize, [column.onResize]);
  const enhancedColumnOnResize = useEvent<OnResizeHandler>((result) =>
    requestAnimationFrame(() => columnOnResize?.(result))
  );

  /**
   * Memoized selectors for order addon
   */
  const orderValue = useMemo(() => order.value, [order.value]);
  const orderOnChange = useMemo(() => order.onChange, [order.onChange]);

  /**
   * Memoized selectors for visibility addon
   */
  const visibilityValue = useMemo(() => visibility.value, [visibility.value]);
  const visibilityOnChange = useMemo(
    () => visibility.onChange,
    [visibility.onChange]
  );

  /**
   * Memoized selectors for sticky addon
   */
  const stickyValue = useMemo(() => sticky.value, [sticky.value]);
  const stickyEnabled = useMemo(() => sticky.enabled, [sticky.enabled]);
  const stickyOnChange = useMemo(() => sticky.onChange, [sticky.onChange]);

  /**
   * Memoized selectors for sort addon
   */
  const sortValue = useMemo(() => {
    if (!sort.value) return;

    const desc = sort.value.startsWith("-");
    const id = desc ? sort.value.slice(1) : sort.value;

    return [{ id, desc }];
  }, [sort.value]);
  const sortOnChange = useMemo(() => sort.onChange, [sort.onChange]);

  const setTemplate = useEvent((template: string) => {
    if (style && "--table-template-columns" in style) return;

    wrapperRef.current?.style.setProperty("--table-template-columns", template);
  });

  const mapSelectValueToRowSelection = useCallback(
    () =>
      mapExternalValueToInternalValue<Data, RowSelectionState>({
        data: enhancedData,
        value: selectValue,
        reference: selectReference,
      }),
    [enhancedData, selectReference, selectValue]
  );

  const [internalRowSelection, setInternalRowSelection] =
    useState<RowSelectionState>(mapSelectValueToRowSelection());

  const rowSelection = useMemo(() => {
    if (selectValue === undefined) {
      if (!selectOnChange) return {} as RowSelectionState;

      return internalRowSelection;
    }

    return mapSelectValueToRowSelection();
  }, [
    selectValue,
    selectOnChange,
    internalRowSelection,
    mapSelectValueToRowSelection,
  ]);

  const mapExpandValueToExpanded = useCallback(
    () =>
      mapExternalValueToInternalValue<Data, ExpandedStateList>({
        data: enhancedData,
        value: expandValue,
        reference: expandReference,
      }),
    [enhancedData, expandReference, expandValue]
  );

  const [internalExpanded, setInternalExpanded] = useState<ExpandedStateList>(
    mapExpandValueToExpanded()
  );

  const expanded = useMemo(
    () =>
      expandValue === undefined ? internalExpanded : mapExpandValueToExpanded(),
    [expandValue, internalExpanded, mapExpandValueToExpanded]
  );

  const rowSelectionCount = Object.keys(rowSelection).length;

  const updateLazyChildren = useEvent(async (data: ExpandData<Data>[]) => {
    if (!expandGetLazyChildren) return;

    const rowsToGetChildren = data.filter(
      (row) =>
        !status.some(
          (item) =>
            dotObject.get(item, expandReference) ===
            dotObject.get(row, expandReference)
        )
    );

    if (!rowsToGetChildren.length) return;

    rowsToGetChildren.forEach(async (item) => {
      setStatus((previousStatus) => [
        ...previousStatus,
        {
          [expandReference]: dotObject.get(item, expandReference),
          status: "loading",
        },
      ]);

      const children = await expandGetLazyChildren(item);

      setInternalData((previousData) => {
        const path = `${item.path}.data`;
        const previousChildren = dotObject.get(previousData, path) || [];

        return dotObject.set(previousData, path, [
          ...previousChildren,
          ...children,
        ]);
      });

      setStatus((previousStatus) =>
        previousStatus.map((item) =>
          dotObject.get(item, expandReference) ===
          dotObject.get(item, expandReference)
            ? { ...item, status: "success" }
            : item
        )
      );
    });
  });

  const onSortingChange = useEvent<OnChangeFn<SortingState>>(
    (updaterOrValue) => {
      const [{ id, desc }] =
        typeof updaterOrValue === "function"
          ? updaterOrValue(sortValue!)
          : updaterOrValue;

      sortOnChange?.(desc ? `-${id}` : id);
    }
  );

  const onExpandedChange = useEvent<OnChangeFn<ExpandedState>>(
    (updaterOrValue) => {
      let nextExpanded: ExpandedStateList | undefined;

      if (updaterOrValue === true) {
        nextExpanded = flattenData(enhancedData);
      } else if (typeof updaterOrValue === "function") {
        nextExpanded = updaterOrValue(expanded) as ExpandedStateList;
      }

      if (!nextExpanded) return;

      const initialValue = {} as ExpandData<Data>;
      setInternalExpanded(nextExpanded);
      const value = Object.keys(nextExpanded).map((indexes) =>
        indexes.split(".").reduce((acc, index) => {
          const path = indexes.split(".").join(".data.");
          const item =
            acc === initialValue
              ? enhancedData
              : (acc.data as ExpandData<Data>[]);

          return { ...dotObject.get(item, index), path };
        }, initialValue)
      );
      expandOnChange?.(value);
      updateLazyChildren(value);
    }
  );

  const onRowSelectionChange = useEvent<OnChangeFn<RowSelectionState>>(
    (updaterOrValue) => {
      if (typeof updaterOrValue !== "function") return;

      const initialValue = {} as SelectedData<Data>;
      const nextRowSelection = updaterOrValue(rowSelection);
      setInternalRowSelection(nextRowSelection);
      const value = Object.keys(nextRowSelection).map((indexes) =>
        indexes.split(".").reduce((acc, index) => {
          const path = indexes.split(".").join(".data.");
          const item =
            acc === initialValue
              ? enhancedData
              : (acc.data as ExpandData<Data>[]);

          return { ...dotObject.get(item, index), path };
        }, initialValue)
      );

      if (!selectMultiple) {
        (selectOnChange as SingleSelectAddon<Data>["onChange"])?.(value[0]);
      } else {
        (selectOnChange as MultipleSelectAddon<Data>["onChange"])?.(value);
      }
    }
  );

  const enhancedIsLoadingRow = useCallback(
    (data: Data) => {
      const isLazyLoadingChildren = status.some(
        (item) =>
          dotObject.get(item, expandReference) ===
            dotObject.get(data, expandReference) && item.status === "loading"
      );

      return rowIsLoading?.(data) || isLazyLoadingChildren;
    },
    [expandReference, rowIsLoading, status]
  );

  const columnHelper = useMemo(() => createColumnHelper<Data>(), []);

  const enhancedOrder = useDeepMemo(() => {
    const orderToUse = orderValue ?? contextOrder;

    /**
     * This is an edge case we need to handle to cover multiple table
     */
    const hasSelect = orderToUse?.includes("select");

    return orderToUse
      ? [
          ...(selectMultiple !== undefined && !hasSelect ? ["select"] : []),
          ...orderToUse,
        ]
      : undefined;
  }, [orderValue, selectMultiple, contextOrder]);

  const enhancedVisibility = useMemo(
    () => visibilityValue ?? contextVisibility,
    [visibilityValue, contextVisibility]
  );

  const enhancedSticky = useMemo(
    () => ({ left: [], right: [], ...(stickyValue ?? contextSticky ?? {}) }),
    [stickyValue, contextSticky]
  );

  const renderRowAddon = useCallback<RenderRowAddonHandler<Data>>(
    ({ data, ...props }) => {
      if (enhancedIsLoadingRow(data)) {
        return (
          <Loader data-testid={suffixify(testId, "loader-row")} {...props} />
        );
      }

      if (rowIsError?.(data)) {
        return (
          <Icon
            name="exclamation-circle"
            color="error-200"
            aria-label="Error"
            data-testid={suffixify(testId, "error-row")}
            {...props}
          />
        );
      }

      return null;
    },
    [enhancedIsLoadingRow, rowIsError, testId]
  );

  const generateFooterColumn = useCallback(
    (column: Column<Data>) =>
      (({ table }) => {
        const rows = table.getRowModel().flatRows.map((row) => row.original);

        const footer = is.object(column.footer)
          ? column.footer.render
          : column.footer;

        let content = null;

        if (is.function(footer)) {
          content = footer(rows);
        } else {
          content = footer;
        }

        return <div className={styles["cell"]}>{content}</div>;
      }) as ColumnDef<Data, unknown>["footer"],
    []
  );

  const generateRenderColumn = useCallback(
    (column: RenderColumn<Data>) =>
      columnHelper.display({
        sortDescFirst: column.sortable !== "asc",
        enableSorting: !!column.sortable,
        id: column.id,
        cell: ({ table, row, column: tableColumn }) => (
          <TableContext.Consumer>
            {({ select, renderRowAddon }) => {
              const isExpanded = row.getIsExpanded();
              const isSelected = row.getIsSelected();
              const isExpandable = row.getCanExpand();
              const isFirstColumn =
                tableColumn.getIndex() ===
                (selectMultiple !== undefined || hasPlaceholderSelect ? 1 : 0);

              const rowData = {
                ...row.original,
                expanded: isExpanded,
                selected: isSelected,
              } as Row<Data>;

              const rowAddon =
                !select.onChange && isFirstColumn
                  ? renderRowAddon({
                      data: rowData,
                      className: styles["prefix"],
                    })
                  : null;

              let prefix = rowAddon;

              if (!prefix && isFirstColumn && isExpandable) {
                prefix = (
                  <Icon
                    name={isExpanded ? "caret-down" : "caret-right"}
                    variant="solid"
                    className={cn(styles["prefix"], {
                      [styles["-expanded"]]: isExpanded,
                      [styles["-collapsed"]]: !isExpanded,
                    })}
                  />
                );
              }

              const render = is.object(column.render)
                ? column.render.render
                : column.render;

              let content = null;

              if (is.function(render)) {
                content = render(rowData, row.index);
              } else if (is.string(render)) {
                content = dotObject.get(rowData, render);
              }

              return (
                <div
                  className={cn(styles["cell"], {
                    [styles["-first"]]: isFirstColumn,
                    [styles["-prefix"]]: prefix,
                    [styles["-placeholder"]]:
                      !prefix && isFirstColumn && table.getCanSomeRowsExpand(),
                  })}
                >
                  {prefix}
                  <Wrapper
                    when={isFirstColumn && isExpandable}
                    render={(children) => (
                      <span className={styles["expandable-content"]}>
                        {children}
                      </span>
                    )}
                  >
                    {content}
                  </Wrapper>
                </div>
              );
            }}
          </TableContext.Consumer>
        ),
        header: ({ table, header }) => (
          <TableContext.Consumer>
            {({ testId }) => {
              /**
               * @todo replace it with header.column.getCanSort() as soon as react-table
               * fixes the bug with sorting https://github.com/TanStack/table/issues/4136
               */
              const canSort = column.sortable;
              const isSorted = header.column.getIsSorted();
              const isExpandable = table.getCanSomeRowsExpand();
              const isFirstColumn =
                header.column.getIndex() ===
                (selectMultiple !== undefined || hasPlaceholderSelect ? 1 : 0);

              return (
                <div
                  className={cn(styles["inner-column"], {
                    [styles["-sortable"]]: canSort,
                  })}
                >
                  <div className={styles["cell-content"]}>
                    {isExpandable && isFirstColumn && (
                      <div
                        className={cn(styles["expand-all"], {
                          [styles["-expanded"]]: table.getIsAllRowsExpanded(),
                        })}
                      >
                        <button
                          type="button"
                          onClick={() => table.toggleAllRowsExpanded()}
                        >
                          <Icon
                            name={
                              table.getIsAllRowsExpanded()
                                ? "caret-down"
                                : "caret-right"
                            }
                            variant="solid"
                          />
                        </button>
                      </div>
                    )}
                    {column.name}
                  </div>
                  {canSort && (
                    <button
                      type="button"
                      className={cn(styles["sort-button"], {
                        [styles["-asc"]]: isSorted
                          ? isSorted === "asc"
                          : column.sortable === "asc",
                        [styles["-desc"]]: isSorted
                          ? isSorted === "desc"
                          : column.sortable !== "asc",
                      })}
                      onClick={() => header.column.toggleSorting()}
                      data-sort={isSorted ? isSorted : undefined}
                      data-testid={suffixify(testId, `sort-by-${column.id}`)}
                    >
                      <Icon
                        className={styles["sort-icon"]}
                        name="caret-up"
                        size="sm"
                        variant="solid"
                      />
                      <Icon
                        className={styles["sort-icon"]}
                        name="caret-down"
                        size="sm"
                        variant="solid"
                      />
                    </button>
                  )}
                </div>
              );
            }}
          </TableContext.Consumer>
        ),
        footer: column.footer ? generateFooterColumn(column) : undefined,
      }),
    [columnHelper, generateFooterColumn, selectMultiple, hasPlaceholderSelect]
  );

  const generateGroupColumn = useCallback(
    (column: GroupColumn<Data>): ColumnDef<Data, unknown> =>
      columnHelper.group({
        id: column.id,
        header: () => column.name ?? "",
        footer: column.footer ? generateFooterColumn(column) : undefined,
        columns: column.columns.map((innerColumn) =>
          isRenderColumn(innerColumn)
            ? generateRenderColumn(innerColumn)
            : generateGroupColumn(innerColumn)
        ),
      }),
    [columnHelper, generateFooterColumn, generateRenderColumn]
  );

  const curriedToggleSelected = useCallback<CurriedToggleSelectedHandler<Data>>(
    ({ row, table }) =>
      (checked) => {
        const rowParent = row.getParentRow();

        const visibleRows = (
          rowParent?.subRows ?? table.getCoreRowModel().rows
        ).filter((row) => rowIsVisible?.(row.original) !== false);

        if (
          shiftKeyRef.current &&
          selectMultiple === true &&
          lastSelectedRowRef.current &&
          lastSelectedRowRef.current.id !== row.id
        ) {
          const correctedRowIndex = visibleRows.findIndex(
            (item) => item.id === lastSelectedRowRef.current?.id
          );

          const correctedLastSelectedRowIndex = visibleRows.findIndex(
            (item) => item.id === row.id
          );

          const startIndex = Math.min(
            correctedLastSelectedRowIndex,
            correctedRowIndex
          );

          const endIndex = Math.max(
            correctedLastSelectedRowIndex,
            correctedRowIndex
          );

          const rowsToToggle = visibleRows.slice(startIndex, endIndex + 1);

          table.setRowSelection((old) => {
            rowsToToggle.forEach((row) => {
              if (!row.getCanSelect()) return;

              if (checked) {
                old[row.id] = checked;

                row.subRows.forEach((subRow) => {
                  if (subRow.getCanSelect()) {
                    old[subRow.id] = checked;
                  }
                });
              } else {
                row.subRows.forEach((subRow) => {
                  delete old[subRow.id];
                });
                delete old[row.id];
              }
            });

            return { ...old };
          });
        } else {
          row.toggleSelected(checked);
        }

        lastSelectedRowRef.current = row;
      },
    [rowIsVisible, selectMultiple]
  );

  const enhancedColumns = useMemo(() => {
    const cells: ColumnDef<Data, unknown>[] = [];

    if (selectMultiple !== undefined) {
      cells.push({
        id: "select",
        header: selectMultiple
          ? ({ table }) => (
              <TableContext.Consumer>
                {({ testId, tableId }) => {
                  const rowSelectionCount =
                    table.getSelectedRowModel().flatRows.length;

                  return (
                    <TableAddonLeft>
                      <TableSelect
                        id={suffixify(tableId, "select-all")}
                        label={
                          rowSelectionCount
                            ? `${rowSelectionCount} selected`
                            : "Select all"
                        }
                        checked={table.getIsAllRowsSelected()}
                        onChange={table.toggleAllRowsSelected}
                        data-testid={suffixify(testId, "select-all")}
                        indeterminate={table.getIsSomeRowsSelected()}
                      />
                    </TableAddonLeft>
                  );
                }}
              </TableContext.Consumer>
            )
          : undefined,
        cell: ({ table, row: item }) => (
          <TableContext.Consumer>
            {({ testId, tableId, select, renderRowAddon }) => {
              const addon = renderRowAddon({
                data: item.original,
                className: styles["addon"],
              });

              const isDisabled = select.isDisabled?.(item.original);
              const isSelectable = select.isSelectable?.(item.original);

              let label = `Select row ${item.id}`;

              if (isDisabled) {
                label = typeof isDisabled === "string" ? isDisabled : "";
              }

              return (
                <TableAddonLeft
                  offset={select.offset}
                  placement={select.placement}
                  className={cn({ [styles["-switcher"]]: addon })}
                >
                  {addon}
                  {isSelectable && (
                    <TableSelect
                      id={suffixify(tableId, item.id)}
                      name={suffixify(tableId, "select-row")}
                      value={item.id}
                      label={label}
                      checked={item.getIsSelected()}
                      onChange={curriedToggleSelected({ row: item, table })}
                      disabled={!!isDisabled}
                      multiple={selectMultiple}
                      className={styles["checkbox"]}
                      data-testid={suffixify(testId, "select-row")}
                      indeterminate={
                        selectMultiple
                          ? item.getCanSelectSubRows()
                            ? item.getIsSomeSelected()
                            : false
                          : undefined
                      }
                    />
                  )}
                </TableAddonLeft>
              );
            }}
          </TableContext.Consumer>
        ),
      });
    }

    columns.forEach((column) => {
      if (isGroupColumn(column)) {
        cells.push(generateGroupColumn(column));
      } else if (isRenderColumn(column)) {
        cells.push(generateRenderColumn(column));
      }
    });

    return cells;
  }, [
    columns,
    selectMultiple,
    generateGroupColumn,
    generateRenderColumn,
    curriedToggleSelected,
  ]);

  const enableRowSelection = useCallback(
    (item: ReactTableRow<Data>) =>
      !selectIsDisabled?.(item.original) && selectIsSelectable(item.original),
    [selectIsDisabled, selectIsSelectable]
  );

  const enableSorting = useMemo(
    () => hasColumnAttr({ attr: "sortable", columns }),
    [columns]
  );

  const getSubRows = useCallback((row: Data) => row.data as Data[], []);

  const getRowCanExpand = useCallback(
    (row: ReactTableRow<Data>) => row.original.data !== undefined,
    []
  );

  /**
   * Since react table doesn't pass custom column props to their
   * columns we need another way to access our custom props
   */
  const getRenderColumnProps = useCallback(
    (id: string) => getColumnById({ id, type: "render", columns }),
    [columns]
  );

  const getColumProps = useCallback(
    (id: string) => getColumnById({ id, columns }),
    [columns]
  );

  const adjustColumnWidth = useCallback(() => {
    if (!internalRef.current) return;

    let widths = getColumnsWidths(internalRef.current);

    const leafColumns = getLeafColumns(columns);

    const visibleColumns = enhancedVisibility
      ? leafColumns.filter((column) => enhancedVisibility[column.id] !== false)
      : leafColumns;

    if (enhancedOrder) {
      visibleColumns.sort(
        (a, b) => enhancedOrder.indexOf(a.id) - enhancedOrder.indexOf(b.id)
      );
    }

    let columnsLength = visibleColumns.length;

    if (selectMultiple !== undefined) {
      columnsLength += 1;
    }

    if (hasExpand || rowOnClick || rowRender) {
      columnsLength += 1;
    }

    if (widths.length < columnsLength && !loading && enhancedData.length > 0) {
      widths = Array.from({ length: columnsLength }).fill(36) as number[];
    }

    const hasFillColumn = visibleColumns.some((column) =>
      hasColumnAttr({ attr: "width", value: "fill", columns: [column] })
    );

    const adjustedColumns =
      selectMultiple !== undefined
        ? [undefined, ...visibleColumns]
        : visibleColumns;

    const hasOnlyOneColumn = visibleColumns.length === 1;

    /**
     * This is a workaround to handle the fill column when we have sticky columns
     */
    let nextIsFill = false;

    const template = widths.reduce((acc, width, index) => {
      const column = adjustedColumns[index];

      const isSelectColumn = selectMultiple !== undefined && index === 0;

      let max = `${width}px`;
      let min = `${width}px`;

      if (column && nextIsFill) {
        column.width = "fill";
        nextIsFill = false;
      }

      if (column?.width) {
        min = `${column.width === "fill" ? width : column.width}px`;
        max = column.width === "fill" ? "1fr" : `${column.width}px`;

        if (column.width === "fill" && hasOnlyOneColumn) {
          min = "min-content";
        }
      }

      if (is.number(column?.minWidth)) {
        min = `${column.minWidth}px`;
      } else if (column?.minWidth === "content") {
        min = "min-content";
      }

      if (!hasFillColumn && !isSelectColumn && width !== 0) {
        max = "1fr";
      }

      if (is.number(column?.maxWidth)) {
        max = `${column.maxWidth}px`;
      } else if (column?.maxWidth === "content") {
        max = "max-content";
      }

      if (column?.id && enhancedSticky.left.includes(column.id)) {
        max = `${width}px`;
        min = `${width}px`;
        nextIsFill = true;
      }

      return `${acc} minmax(${min}, ${max})`.trim();
    }, "");

    enhancedColumnOnResize({ template, widths });
    setTemplate(template);
  }, [
    columns,
    loading,
    rowRender,
    hasExpand,
    rowOnClick,
    setTemplate,
    enhancedData,
    enhancedOrder,
    enhancedSticky,
    selectMultiple,
    enhancedVisibility,
    enhancedColumnOnResize,
  ]);

  const table = useReactTable({
    data: enhancedData,
    state: {
      sorting: sortValue,
      expanded,
      columnOrder: enhancedOrder,
      rowSelection,
      columnPinning: enhancedSticky,
      columnVisibility: enhancedVisibility,
    },
    enableColumnPinning: stickyEnabled,
    columns: enhancedColumns,
    getSubRows,
    enableSorting,
    manualSorting: true,
    getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange,
    onExpandedChange,
    enableRowSelection,
    enableSortingRemoval: false,
    getExpandedRowModel: getExpandedRowModel(),
    onRowSelectionChange,
    enableSubRowSelection: selectAutoSelectChild,
    enableMultiRowSelection: selectMultiple,
  });

  const rows = table.getRowModel().rows;

  const hasGroupHeaders = useMemo(
    () => columns.some((column) => isGroupColumn(column)),
    [columns]
  );

  const visibleRows = useMemo(
    () => rows.filter((row) => rowIsVisible?.(row.original) !== false),
    [rows, rowIsVisible]
  );

  /**
   * This is a workaround to handle select all when we have invisible rows.
   * I tried to use native global filter that react table provides but it
   * doesn't work as expected so to make it work as expected we need to
   * rewrite the toggleAllRowsSelected function.
   */
  table.toggleAllRowsSelected = useCallback(() => {
    table.setRowSelection((old) => {
      const visibleRows = table
        .getPreGroupedRowModel()
        .flatRows.filter((row) => rowIsVisible?.(row.original) !== false);

      let { rowSelection } = table.getState();

      let isAllRowsSelected = Boolean(
        visibleRows.length && Object.keys(visibleRows).length
      );

      if (
        isAllRowsSelected &&
        visibleRows.some((row) => row.getCanSelect() && !rowSelection[row.id])
      ) {
        isAllRowsSelected = false;
      }

      rowSelection = { ...old };

      visibleRows.forEach((row) => {
        if (row.getCanSelect() && !isAllRowsSelected) {
          rowSelection[row.id] = true;
        } else {
          delete rowSelection[row.id];
        }
      });

      return rowSelection;
    });
  }, [table, rowIsVisible]);

  /**
   * This is a workaround to handle expand all when we have invisible rows.
   * I tried to use native global filter that react table provides but it
   * doesn't work as expected so to make it work as expected we need to
   * rewrite the getIsAllRowsExpanded function.
   */
  table.getIsAllRowsExpanded = useCallback(() => {
    const visibleRows = table
      .getPreGroupedRowModel()
      .flatRows.filter((row) => rowIsVisible?.(row.original) !== false);

    const { expanded } = table.getState();

    if (typeof expanded === "boolean") return expanded;

    let isAllRowsSelected = Boolean(
      visibleRows.length && Object.keys(visibleRows).length
    );

    if (
      isAllRowsSelected &&
      visibleRows.some((row) => row.getCanExpand() && !expanded[row.id])
    ) {
      isAllRowsSelected = false;
    }

    return isAllRowsSelected;
  }, [rowIsVisible, table]);

  /**
   * This is a workaround to handle expand all when we have invisible rows.
   * I tried to use native global filter that react table provides but it
   * doesn't work as expected so to make it work as expected we need to
   * rewrite the toggleAllRowsExpanded function.
   */
  const toggleAllRowsExpanded = useCallback(
    (expand?: boolean) => {
      const isAllRowsExpanded = table.getIsAllRowsExpanded();
      const collapse = expand === false || isAllRowsExpanded;
      table.setExpanded(collapse ? () => ({}) : true);
      expandOnChangeAll?.(!collapse);
    },
    [expandOnChangeAll, table]
  );

  table.toggleAllRowsExpanded = toggleAllRowsExpanded;

  const increaseViewportBy = useMemo(
    () => (providerVirtualScroll ? 0 : Infinity),
    [providerVirtualScroll]
  );

  const renderBody = () => {
    let content: ReactNode = null;

    if (visibleRows.length > 0) {
      const scrollableParent =
        getScrollableParent(bodyRef.current) ?? undefined;

      content = (
        <Virtuoso
          ref={virtuosoRef}
          totalCount={visibleRows.length}
          itemContent={(rowIndex) => {
            const item = visibleRows[rowIndex];
            const cells = item.getVisibleCells();
            const cellsLength = cells.length;

            const canExpand =
              item.getCanExpand() &&
              !enhancedIsLoadingRow(item.original) &&
              !rowIsError?.(item.original);

            let isLastChild = false;

            const parent = item.getParentRow();

            if (parent) {
              isLastChild =
                parent.subRows[parent.subRows.length - 1].id === item.id;
            }

            return (
              <div
                key={item.id}
                role="row"
                style={{ "--table-row-depth": item.depth ?? 0 }}
                data-row=""
                className={cn(styles["row"], {
                  [styles["-last"]]: rowIndex === visibleRows.length - 1,
                  [styles["-actionable"]]: rowOnClick || rowRender || canExpand,
                  [styles["-expanded"]]: item.getIsExpanded(),
                  [styles["-last-child"]]: isLastChild,
                })}
                data-testid={suffixify(testId, "body-row", rowIndex)}
                aria-selected={item.getIsSelected()}
              >
                {cells.map((cell, cellIndex) => {
                  const isLast = cellsLength - 1 === cellIndex ? "" : undefined;
                  const columnProps = getRenderColumnProps(cell.column.id);
                  const colSpan =
                    is.object(columnProps?.render) &&
                    "colSpan" in columnProps.render
                      ? (columnProps.render.colSpan ?? 1)
                      : 1;

                  return (
                    <div
                      key={cell.id}
                      role="cell"
                      data-cell=""
                      data-last={isLast}
                      className={cn(
                        styles["column"],
                        styles[`-text-${columnProps?.textAlign || "left"}`],
                        styles[`-align-${columnProps?.align || "center"}`],
                        styles[`-justify-${columnProps?.justify || "normal"}`],
                        {
                          [styles[
                            `-highlight-background-${columnProps?.highlight}`
                          ]]: columnProps?.highlight,
                        }
                      )}
                      style={{
                        ...getColumnGridStyle(
                          cellIndex + 1,
                          colSpan + cellIndex + 1
                        ),
                        ...getCommonStickyStyles({ column: cell.column }),
                      }}
                      aria-hidden={colSpan === 0}
                      data-testid={suffixify(
                        testId,
                        "body-row",
                        rowIndex,
                        "column",
                        cellIndex
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </div>
                  );
                })}
                {(canExpand || rowOnClick) && (
                  <TableCellAction
                    onClick={() => {
                      rowOnClick?.(item.original);
                      canExpand && item.toggleExpanded();
                    }}
                    data-testid={testId}
                  >
                    {canExpand
                      ? item.getIsExpanded()
                        ? "Collapse"
                        : "Expand"
                      : "Open"}
                  </TableCellAction>
                )}
                {rowRender && (
                  <TableCellAction
                    render={(cellActionProps) =>
                      rowRender?.({
                        row: item.original,
                        props: cellActionProps,
                      })
                    }
                    data-testid={testId}
                  />
                )}
              </div>
            );
          }}
          useWindowScroll={!scrollableParent}
          customScrollParent={scrollableParent}
          increaseViewportBy={increaseViewportBy}
        />
      );
    } else if (loading) {
      content = (
        <div
          role="row"
          data-row=""
          className={cn(styles["row"], styles["-last"])}
        >
          <div
            role="cell"
            data-cell=""
            style={getColumnGridStyle(1)}
            data-last
            className={styles["column"]}
            data-empty=""
          >
            <Flex
              width="full"
              align="center"
              justify="center"
              padding={["4xl", "none"]}
            >
              <Loader data-testid={suffixify(testId, "loader")} />
            </Flex>
          </div>
        </div>
      );
    } else if (emptyState === "error") {
      content = (
        <div
          role="row"
          data-row=""
          className={cn(styles["row"], styles["-last"])}
        >
          <div
            role="cell"
            data-cell=""
            style={getColumnGridStyle(1)}
            data-last
            className={styles["column"]}
            data-empty=""
          >
            <Flex
              width="full"
              align="center"
              justify="center"
              padding={["4xl", "none"]}
            >
              <EmptyState
                data-testid={suffixify(testId, "error-state")}
                title="An error occurred"
                subtitle="Reload the page to see results"
                icon="exclamation-triangle"
                action={{
                  children: "Reload page",
                  onClick: () => window.location.reload(),
                }}
              />
            </Flex>
          </div>
        </div>
      );
    } else if (emptyState) {
      content = (
        <div
          role="row"
          data-row=""
          className={cn(styles["row"], styles["-last"])}
        >
          <div
            role="cell"
            data-cell=""
            style={getColumnGridStyle(1)}
            data-last
            className={styles["column"]}
            data-empty=""
          >
            <Flex
              width="full"
              align="center"
              justify="center"
              padding={["4xl", "none"]}
            >
              <EmptyState
                data-testid={suffixify(testId, "empty-state")}
                {...emptyState}
              />
            </Flex>
          </div>
        </div>
      );
    }

    return content ? (
      <div
        ref={bodyRef}
        role="rowgroup"
        className={styles["body"]}
        data-body=""
      >
        {content}
      </div>
    ) : (
      content
    );
  };

  const body = renderBody();

  const headerOffset =
    typeof header.sticky === "object" ? header.sticky.offset : 0;
  const headerStyle = {
    "--table-header-offset": `${headerOffset}px`,
  };

  const footerOffset =
    typeof footer.sticky === "object" ? footer.sticky.offset : 0;
  const footerStyle = {
    "--table-footer-offset": `${footerOffset}px`,
  };

  const enhancedStyle = {
    "--table-max-height": maxHeight,
    "--table-wrapper-width": wrapperWidth ? `${wrapperWidth}px` : "auto",
    ...style,
  };

  const hasHeader = useMemo(
    () => hasColumnAttr({ attr: "name", columns }),
    [columns]
  );

  const getGroupInfo = useCallback(
    (groups: HeaderGroup<Data>[], def: "header" | "footer") => {
      let visible = false;

      const visibleGroups: HeaderGroup<Data>[] = [];

      groups.forEach((group) => {
        const hasContent = group.headers.some(
          (cell) =>
            !!(cell.isPlaceholder
              ? null
              : flexRender(cell.column.columnDef[def], cell.getContext()))
        );

        if (hasContent) visibleGroups.push(group);
      });

      let content: ReactNode = visibleGroups.map((group, groupIndex) => {
        const isLastRow = groupIndex === visibleGroups.length - 1;
        const isFirstRow = groupIndex === 0;

        return (
          <div
            key={group.id}
            role="row"
            data-row=""
            className={cn(styles["row"], {
              [styles["-last"]]:
                def === "footer" && groupIndex === visibleGroups.length - 1,
            })}
            data-testid={suffixify(testId, def, "row", groupIndex)}
          >
            {group.headers.map((cell, cellIndex) => {
              const columnProps = getColumProps(cell.column.id);
              const colSpan =
                def === "footer" && is.object(columnProps?.footer)
                  ? (columnProps.footer.colSpan ?? cell.colSpan)
                  : cell.colSpan;
              const isSorted = cell.column.getIsSorted();
              const justify = columnProps?.justify
                ? columnProps.justify
                : groupIndex !== groups.length - 1
                  ? "center"
                  : "normal";

              const cellContent = cell.isPlaceholder
                ? null
                : flexRender(cell.column.columnDef[def], cell.getContext());

              const isLastColumn = group.headers.length - 1 === cellIndex;

              if (cellContent) {
                visible = true;
              }

              const highlightBackground =
                columnProps &&
                isRenderColumn(columnProps) &&
                columnProps.highlight;

              const highlightBorder =
                !hasGroupHeaders || (isLastRow && isFirstRow)
                  ? highlightBackground
                  : columnProps &&
                    isGroupColumn(columnProps) &&
                    columnProps.highlight;

              if (columnProps && isGroupColumn(columnProps)) {
                /**
                 * We need to override this since react-table doesn't implement
                 * the sticky feature for group headers correctly.
                 */
                cell.column.getIsPinned = () =>
                  cell
                    .getLeafHeaders()
                    .some((header: Header<any, any>) =>
                      enhancedSticky.left.includes(header.id)
                    ) && "left";
              }

              return (
                <TableColumn
                  as={def === "header" ? Tooltip : "div"}
                  {...(def === "header"
                    ? { offset: { x: -5 }, message: columnProps?.message }
                    : {})}
                  key={cell.id}
                  role={def === "header" ? "columnheader" : "cell"}
                  sticky={cell.column.getIsPinned()}
                  style={{
                    ...getColumnGridStyle("span", colSpan),
                    ...getCommonStickyStyles({ def, column: cell.column }),
                  }}
                  columnId={cell.column.id}
                  tableRef={internalRef}
                  data-cell={def !== "header" ? "" : undefined}
                  data-last={isLastColumn ? "" : undefined}
                  aria-sort={
                    def !== "header" || !isSorted
                      ? undefined
                      : isSorted === "asc"
                        ? "ascending"
                        : "descending"
                  }
                  className={cn(
                    styles["column"],
                    styles[`-align-${columnProps?.align || "center"}`],
                    styles[`-justify-${justify}`],
                    {
                      [styles[`-highlight-background-${highlightBackground}`]]:
                        highlightBackground,
                      [styles[`-highlight-border-${highlightBorder}`]]:
                        highlightBorder,
                      [styles[`-text-${columnProps?.textAlign || "left"}`]]:
                        def !== "header",
                    }
                  )}
                  aria-hidden={colSpan === 0}
                  data-testid={suffixify(
                    testId,
                    def,
                    "row",
                    groupIndex,
                    "column",
                    cellIndex
                  )}
                  setColumnSizing={table.setColumnSizing}
                  data-column-header={def === "header" ? "" : undefined}
                >
                  {cellContent}
                </TableColumn>
              );
            })}
          </div>
        );
      });

      if (def === "header") {
        if (!hasHeader) {
          if (visibleRows.length === 0) {
            content = null;
          } else {
            const selectId = suffixify(tableId, "select-all");

            let enhancedContent = null;

            if (selectMultiple) {
              enhancedContent = (
                <div
                  key="select"
                  role="columnheader"
                  style={getColumnGridStyle(1, 2)}
                  className={styles["column"]}
                  data-testid={suffixify(testId, "header-row", 0, "column", 0)}
                >
                  <TableAddonLeft>
                    <TableSelect
                      id={selectId}
                      checked={table.getIsAllRowsSelected()}
                      onChange={table.toggleAllRowsSelected}
                      data-testid={suffixify(testId, "select-all")}
                      indeterminate={table.getIsSomeRowsSelected()}
                    />
                  </TableAddonLeft>
                </div>
              );
            }

            content = (
              <div
                role="row"
                data-row=""
                className={styles["row"]}
                data-testid={suffixify(testId, "header-row", 0)}
              >
                {enhancedContent}
                <div
                  key="header"
                  role="columnheader"
                  style={getColumnGridStyle(enhancedContent ? 2 : 1)}
                  className={styles["column"]}
                  data-testid={suffixify(
                    testId,
                    "header-row",
                    0,
                    "column",
                    enhancedContent ? 1 : 0
                  )}
                  data-last
                >
                  <div className={styles["column-header"]}>
                    {selectMultiple ? (
                      <label
                        htmlFor={selectId}
                        className={styles["select-label"]}
                      >
                        {rowSelectionCount
                          ? `${rowSelectionCount} selected`
                          : "Select all"}
                      </label>
                    ) : enhancedContent ? (
                      <div />
                    ) : null}
                    {header?.render && (
                      <div
                        className={cn(styles["header-addon"], {
                          [styles["-right"]]: enhancedContent,
                        })}
                      >
                        {header?.render()}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          }
        }
      }

      return { visible, content };
    },
    [
      enhancedSticky.left,
      getColumProps,
      hasGroupHeaders,
      hasHeader,
      header,
      rowSelectionCount,
      selectMultiple,
      table,
      tableId,
      testId,
      visibleRows.length,
    ]
  );

  const headersGroup = getGroupInfo(table.getHeaderGroups(), "header");

  const footersGroup = getGroupInfo(table.getFooterGroups(), "footer");

  /**
   * We need to round it down to avoid a bug with multi step dialog
   */
  useResizeObserver(wrapperRef, (el) =>
    setWrapperWidth(Math.floor(el.getBoundingClientRect().width))
  );

  useResizeObserver(internalRef, adjustColumnWidth);

  useEffect(() => {
    queueMicrotask(adjustColumnWidth);
  }, [adjustColumnWidth]);

  useEffect(() => {
    setColumns?.(columns);
  }, [columns, setColumns]);

  useEffect(() => {
    setStickyEnabled?.(stickyEnabled ?? false);
  }, [stickyEnabled, setStickyEnabled]);

  useEffect(() => {
    const orderWithoutSelect = enhancedOrder?.filter((id) => id !== "select");

    if (orderWithoutSelect) orderOnChange?.(orderWithoutSelect);
  }, [orderOnChange, enhancedOrder]);

  useEffect(() => {
    if (enhancedVisibility) visibilityOnChange?.(enhancedVisibility);
  }, [visibilityOnChange, enhancedVisibility]);

  useEffect(() => {
    if (enhancedSticky) stickyOnChange?.(enhancedSticky);
  }, [stickyOnChange, enhancedSticky]);

  useEffect(() => {
    setDescendantsIds?.((ids) => [...ids, tableId]);

    return () => {
      setDescendantsIds?.((ids) => ids.filter((id) => id !== tableId));
    };
  }, [setDescendantsIds, tableId]);

  useEffect(() => {
    /**
     * We do this check to guarantee that we don't unnecessary change the status reference
     */
    setStatus((previousStatus) =>
      previousStatus.length === 0 ? previousStatus : []
    );
    debouncedSetInternalData(data);
  }, [data, debouncedSetInternalData]);

  useEffect(() => {
    const header = headerRef.current;
    const placeholder = placeholderRowRef.current;

    if (!header || !placeholder) return;

    const scrollableParent = getScrollableParent(bodyRef.current) ?? undefined;

    const observer = new IntersectionObserver(
      ([e]) => {
        if (scrollableParent?.scrollTop === 0) return;

        header.classList.toggle(styles["-stuck"], e.intersectionRatio < 1);
      },
      { threshold: [0, 1], root: scrollableParent }
    );

    observer.observe(placeholder);

    return () => {
      observer.disconnect();
    };
  }, [headersGroup.visible, header.hide]);

  useEffect(() => {
    onExpandedChange((expanded) =>
      diffInternalValueToExternalValue<Data[], ExpandedStateList>({
        next: enhancedData,
        prev: previousData,
        value: expanded as ExpandedStateList,
        reference: expandReference,
      })
    );
  }, [enhancedData, previousData, onExpandedChange, expandReference]);

  useEffect(() => {
    onRowSelectionChange((selected) =>
      diffInternalValueToExternalValue<Data[], RowSelectionState>({
        next: enhancedData,
        prev: previousData,
        value: selected,
        reference: selectReference,
      })
    );
  }, [enhancedData, previousData, onRowSelectionChange, selectReference]);

  useKeydown((e) => {
    shiftKeyRef.current = e.shiftKey;
  });

  useKeyup((e) => {
    shiftKeyRef.current = e.shiftKey;
  });

  useImperativeHandle(ref, () => {
    const rootEl = wrapperRef.current!;

    rootEl.scrollToIndex = debounce((index: number) => {
      virtuosoRef.current?.scrollToIndex({
        index,
        offset:
          (header.sticky ?? true)
            ? -(headerRef.current?.getBoundingClientRect().height ?? 0)
            : 0,
        align: "start",
        behavior: "smooth",
      });
    }, SCROLL_TIMEOUT);

    rootEl.expandAll = () => toggleAllRowsExpanded(true);

    rootEl.collapseAll = () => toggleAllRowsExpanded(false);

    return rootEl;
  }, [header.sticky, toggleAllRowsExpanded]);

  return (
    <div
      ref={wrapperRef}
      style={enhancedStyle}
      className={cn(className, styles["wrapper"], {
        [styles[`-${size}`]]: size,
        [styles["-loading"]]: loading,
        [styles["-bordered"]]: bordered,
        [styles["-has-body"]]: !!body,
        [styles["-has-header"]]: headersGroup.visible,
        [styles["-has-footer"]]: footersGroup.visible,
        [styles["-scrollable"]]: maxHeight !== "auto",
        [styles["-addon-left"]]: selectMultiple !== undefined,
      })}
    >
      <div
        id={id}
        ref={internalRef}
        role="table"
        data-table=""
        className={styles["table"]}
        data-testid={testId}
      >
        <div
          aria-hidden="true"
          className={cn(styles["row"], styles["-placeholder"])}
          ref={placeholderRowRef}
          style={{ top: `-${headerOffset + 1}px` }}
        >
          {enhancedColumns.map((_, i) => (
            <div key={i} />
          ))}
        </div>
        <TableContext.Provider
          value={{
            testId,
            select: {
              offset: selectOffset,
              multiple: selectMultiple,
              onChange: selectOnChange,
              placement: selectPlacement,
              isDisabled: selectIsDisabled,
              isSelectable: selectIsSelectable,
            },
            tableId,
            renderRowAddon,
          }}
        >
          {headersGroup.visible && header.hide !== true && (
            <div
              ref={headerRef}
              role="rowgroup"
              style={headerStyle}
              className={cn(styles["header"], {
                [styles["-sticky"]]: header.sticky ?? true,
              })}
              data-header=""
            >
              {headersGroup.content}
            </div>
          )}
          {body}
          {footersGroup.visible && footer.hide !== true && (
            <div
              role="rowgroup"
              style={footerStyle}
              className={cn(styles["footer"], {
                [styles["-sticky"]]: footer.sticky ?? true,
              })}
              data-footer=""
            >
              {footersGroup.content}
            </div>
          )}
        </TableContext.Provider>
      </div>
      {pagination && pagination.total > 0 && (
        <Pagination
          disabled={loading}
          data-testid={suffixify(testId, "pagination")}
          {...pagination}
        />
      )}
    </div>
  );
};

const ForwardedTable = forwardRef(Table) as <
  Data extends UnknownData & { data?: Data["data"] },
>(
  props: Props<Data> & { ref?: ForwardedRef<Ref> }
) => ReturnType<typeof Table>;

export { ForwardedTable as Table };
