import React, {
  type ComponentProps,
  type ComponentPropsWithoutRef,
  type ElementType,
  forwardRef,
  memo,
  type ReactNode,
  type Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Checkbox,
  CurrencyField,
  Dropdown,
  DropdownComboBoxItem,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  Link,
  ResponsiveProvider,
  Switch,
  Table,
  type TableColumn,
  type TableEmptyState,
  type TableFooterAddon,
  type TableHeaderAddon,
  type TableProps,
  type TableRef,
  type TableRow,
  type TableSelectAddon,
  Text,
  TextField,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import {
  useEvent,
  usePrevious,
  useResponsiveProvider,
} from "@adaptive/design-system/hooks";
import { formatCurrency, is, suffixify } from "@adaptive/design-system/utils";
import type { AccountFilters } from "@hooks/use-accounts-simplified";
import { useCostCodesAccountsSimplified } from "@hooks/use-cost-codes-accounts-simplified";
import type { UseCostCodesSimplifiedProps } from "@hooks/use-cost-codes-simplified";
import type { Option } from "@shared/types";
import { noop } from "@utils/noop";
import cn from "clsx";
import { createContext, useContextSelector } from "use-context-selector";

import { CostCodeAccountComboBox } from "../cost-code-account-combobox";
import { renderCostCodeAccountPlaceholder } from "../cost-code-account-combobox/cost-code-account-combobox-utils";
import { CustomersComboBox } from "../customers-combobox";

import styles from "./items.module.css";

type Field<Value> = (Value extends boolean
  ? { checked: Value }
  : { value: Value }) & {
  disabled?: boolean;
  required?: boolean;
  errorMessage?: ReactNode;
  helperMessage?: ReactNode;
};

type Data = {
  id: number | string;
  extra?: {
    label: string;
    value: ReactNode;
    variant: "warning" | "neutral";
    onClick?: () => void;
  };
  amount: Field<number>;
  footer?: ReactNode;
  description: Field<string>;
  jobCustomer: Field<string | Option>;
  costCodeAccount: Field<string | Option>;
} & (
  | {
      billable?: never;
      closable?: Field<boolean>;
    }
  | {
      billable?: Field<boolean>;
      closable?: never;
    }
);

type Value<Field, ValueType> = {
  id: Data["id"];
  name: Field;
  value: ValueType;
};

type IsNewItemHandler = (item: Data) => boolean;

type IsFocusableItemHandler = (item: Data & { index: number }) => boolean;

type EnhancedDisabled = {
  fields: boolean;
  addLine: boolean;
  salesTax: boolean;
  removeLine: boolean | ((item: Data) => boolean);
  batchUpdate: boolean;
  batchRemove: boolean;
};

type ComponentsProps = {
  costCodeAccount?: {
    accountFilters?: AccountFilters & { enabled?: boolean };
    costCodeFilters?: UseCostCodesSimplifiedProps;
  };
  table?: {
    headerSticky?: Exclude<TableProps<Data>["header"], undefined>["sticky"];
  };
};

export type ItemsProps = {
  id: string;
  data: Data[];
  total: number;
  onAdd: () => void;
  disabled?: boolean | Partial<EnhancedDisabled>;
  onChange: (
    value:
      | Value<"amount", number>
      | Value<"billable", boolean>
      | Value<"closable", boolean>
      | Value<"description", string>
      | Value<"jobCustomer", Option | undefined>
      | Value<"costCodeAccount", Option | undefined>
  ) => void | boolean;
  onRemove: (id: Data["id"]) => void | false;
  salesTax?: {
    onAdd: () => void;
    amount: number;
    subTotal: number;
    onRemove: () => void;
  };
  isNewItem: IsNewItemHandler;
  "data-testid"?: string;
  componentsProps?: ComponentsProps;
  actions?: ReactNode;
};

type Context = {
  total: ItemsProps["total"];
  testId?: ItemsProps["data-testid"];
  disabled: EnhancedDisabled;
  onChange: ItemsProps["onChange"];
  onRemove: ItemsProps["onRemove"];
  salesTax?: ItemsProps["salesTax"];
  removable: boolean;
  autoFocus: boolean;
  itemsLength: number;
  setAutoFocus: (value: boolean) => void;
  componentsProps?: ComponentsProps;
  isFocusableItem: IsFocusableItemHandler;
};

type LazyErrorMessageFieldProps<T extends ElementType> = {
  as: T;
} & JSX.LibraryManagedAttributes<T, ComponentProps<T>>;

/**
 * We need to wrap our Field to handle the error message behavior
 * like we handle with useForm. It should be temporary until we
 * refactor Bill/Receipt/PO to use useForm hook
 */
const LazyErrorMessage = forwardRef(
  <T extends ElementType>(
    { as: Component, ...props }: LazyErrorMessageFieldProps<T>,
    ref: Ref<HTMLInputElement>
  ) => {
    const [touched, setTouched] = useState(false);

    const onBlur = useEvent((e) => {
      requestAnimationFrame(() => setTouched(true));
      props.onBlur?.(e);
    });

    return (
      <Component
        {...props}
        ref={ref}
        onBlur={onBlur}
        errorMessage={touched ? props.errorMessage : ""}
      />
    );
  }
);

LazyErrorMessage.displayName = "LazyErrorMessage";

const CURRENCY_FORMAT = { currencySign: true, allowNegative: true };

const EXTRA_FIELD_VARIANT = {
  warning: "warning-200",
  neutral: "neutral-900",
} as const;

const AddLineButton = (props: ComponentPropsWithoutRef<typeof Button>) => {
  const { current } = useResponsiveProvider();

  if (current === "mobile") {
    return (
      <Tooltip message="Add line">
        <Button size="sm" {...props}>
          <Icon name="plus" />
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button size="sm" {...props}>
      <Icon name="plus" />
      Add line
    </Button>
  );
};

const ItemsContext = createContext<Context>({
  total: 0,
  disabled: {
    fields: false,
    addLine: false,
    salesTax: false,
    removeLine: false,
    batchUpdate: false,
    batchRemove: false,
  },
  onChange: noop,
  onRemove: noop,
  removable: false,
  autoFocus: false,
  itemsLength: 0,
  setAutoFocus: noop,
  isFocusableItem: () => false,
});

const InfoRender = memo<TableRow<Data> & { index: number }>((row) => {
  const amountRef = useRef<HTMLInputElement>(null);

  const {
    testId,
    disabled,
    onChange,
    onRemove,
    removable,
    autoFocus,
    setAutoFocus,
    componentsProps,
    isFocusableItem,
  } = useContextSelector(ItemsContext, (context) => ({
    testId: context.testId,
    disabled: context.disabled,
    onChange: context.onChange,
    onRemove: context.onRemove,
    removable: context.removable,
    autoFocus: context.autoFocus,
    setAutoFocus: context.setAutoFocus,
    componentsProps: context.componentsProps,
    isFocusableItem: context.isFocusableItem,
  }));

  const shouldShift =
    !disabled.batchUpdate || !disabled.batchRemove || !disabled.addLine;

  const isFocusable = autoFocus && isFocusableItem(row);

  useEffect(() => {
    if (
      isFocusable &&
      amountRef.current &&
      !amountRef.current.dataset.focused
    ) {
      setAutoFocus(false);
      amountRef.current.focus();
      amountRef.current.dataset.focused = "true";
    }
  }, [isFocusable, setAutoFocus]);

  return (
    <Flex width="full" padding={["sm", "md", "sm", "none"]} direction="column">
      <Flex
        gap={{ mobile: "none", tablet: "xl" }}
        width="full"
        direction={{ mobile: "column", tablet: "row" }}
      >
        <LazyErrorMessage
          as={CustomersComboBox}
          onChange={(_: string | string[], option?: Option | Option[]) =>
            onChange({
              id: row.id,
              name: "jobCustomer",
              value: option as Option,
            })
          }
          data-testid={suffixify(testId, "job-customer")}
          {...row.jobCustomer}
          disabled={disabled.fields || row.jobCustomer.disabled}
        />
        <LazyErrorMessage
          as={CostCodeAccountComboBox}
          onChange={(_: string | string[], option?: Option | Option[]) =>
            onChange({
              id: row.id,
              name: "costCodeAccount",
              value: option as Option,
            })
          }
          data-testid={suffixify(testId, "cost-code-account")}
          accountFilters={componentsProps?.costCodeAccount?.accountFilters}
          costCodeFilters={componentsProps?.costCodeAccount?.costCodeFilters}
          {...row.costCodeAccount}
          disabled={disabled.fields || row.costCodeAccount.disabled}
        />
      </Flex>
      <Flex
        gap={{ mobile: "none", tablet: "xl" }}
        width="full"
        direction={{ mobile: "column", tablet: "row" }}
      >
        <Flex gap="xl" shrink={false}>
          <Flex width={{ mobile: "full", tablet: "152px" }}>
            <LazyErrorMessage
              as={CurrencyField}
              ref={amountRef}
              label="Amount"
              align="right"
              onChange={(value: number) =>
                onChange({ id: row.id, name: "amount", value })
              }
              placeholder="0.00"
              data-testid={suffixify(testId, "amount")}
              allowNegative
              {...row.amount}
              disabled={disabled.fields || row.amount.disabled}
            />
          </Flex>
          {row.extra && (
            <Flex
              align="flex-start"
              width={{ mobile: "full", tablet: "120px" }}
              margin={["22px", "none", "none"]}
              direction="column"
            >
              <Text truncate>{row.extra.label}</Text>
              {row.extra.onClick ? (
                <Link
                  as="button"
                  type="button"
                  variant={row.extra.variant}
                  onClick={row.extra.onClick}
                >
                  {typeof row.extra.value === "number"
                    ? formatCurrency(row.extra.value, CURRENCY_FORMAT)
                    : row.extra.value}
                </Link>
              ) : (
                <Text size="md" color={EXTRA_FIELD_VARIANT[row.extra.variant]}>
                  {typeof row.extra.value === "number"
                    ? formatCurrency(row.extra.value, CURRENCY_FORMAT)
                    : row.extra.value}
                </Text>
              )}
            </Flex>
          )}
        </Flex>
        <Flex width="full">
          <TextField
            label="Description"
            onChange={(value: string) =>
              onChange({ id: row.id, name: "description", value })
            }
            changeMode="lazy"
            data-testid={suffixify(testId, "description")}
            placeholder="e.g Deliver and install"
            {...row.description}
            disabled={disabled.fields || row.description.disabled}
          />
        </Flex>
      </Flex>
      <Flex width="full" align="center" justify="space-between">
        {row.billable ? (
          <Checkbox
            id={`billable-${row.id}`}
            label="Billable"
            onChange={(value: boolean) => {
              onChange({
                id: row.id,
                name: "billable",
                value,
              });
            }}
            data-testid={suffixify(testId, "billable")}
            {...row.billable}
            disabled={disabled.fields || row.billable.disabled}
          />
        ) : row.closable ? (
          <Switch
            id={`closable-${row.id}`}
            label={row.closable.checked ? "Opened" : "Closed"}
            onChange={(value: boolean) => {
              onChange({ id: row.id, name: "closable", value });
            }}
            data-testid={suffixify(testId, "closable")}
            {...row.closable}
            disabled={disabled.fields || row.closable.disabled}
          />
        ) : (
          <Flex />
        )}

        {!(is.boolean(disabled.removeLine)
          ? disabled.removeLine
          : disabled.removeLine(row)) && (
          <Flex gap="xl" height="32px">
            {removable && (
              <Button
                size="sm"
                block
                color="neutral"
                variant="ghost"
                onClick={() => onRemove(row.id)}
                data-testid={suffixify(testId, "remove")}
              >
                <Icon name="trash" />
                Delete line
              </Button>
            )}
          </Flex>
        )}
      </Flex>
      {row.footer && (
        <div
          className={cn(styles["item-footer"], {
            [styles["-shift"]]: shouldShift,
          })}
        >
          {row.footer}
        </div>
      )}
    </Flex>
  );
});

InfoRender.displayName = "InfoRender";

const InfoFooter = memo(() => {
  const { total, testId, salesTax, disabled } = useContextSelector(
    ItemsContext,
    (context) => ({
      total: context.total,
      testId: context.testId,
      disabled: context.disabled,
      salesTax: context.salesTax,
      itemsLength: context.itemsLength,
    })
  );

  const shouldShift =
    !disabled.batchUpdate || !disabled.addLine || !disabled.batchRemove;

  return (
    <Flex
      gap="xl"
      align="center"
      width={shouldShift ? "calc(100% - 36px)" : "full"}
      margin={["none", "none", "none", shouldShift ? "-36px" : "none"]}
      padding={["lg", "none"]}
    >
      <Flex grow direction="column">
        <Text
          weight="bold"
          size="xl"
          data-testid={suffixify(testId, "total-amount")}
        >
          Total: {formatCurrency(total, CURRENCY_FORMAT)}
        </Text>
        <Text size="xs" color="neutral-600">
          This total is calculated from the line items above
          {salesTax ? " & tax" : ""}.
        </Text>
      </Flex>
      {salesTax && (
        <Flex direction="column" shrink={false}>
          <Text size="md" weight="bolder">
            Subtotal: {formatCurrency(salesTax.subTotal, CURRENCY_FORMAT)}
          </Text>
          <Flex align="center" gap="sm" margin={["-4px", "none", "none"]}>
            <Text size="sm">
              Sales tax:
              <Text
                as="span"
                style={{ marginLeft: "var(--spacing-lg)" }}
                data-testid={suffixify(testId, "sales-tax-amount")}
              >
                {formatCurrency(salesTax.amount, CURRENCY_FORMAT)}
              </Text>
            </Text>
            {!disabled.salesTax && (
              <>
                {salesTax.amount === 0 ? (
                  <Tooltip
                    as={Button}
                    size="sm"
                    color="primary"
                    variant="text"
                    message="Add sales tax"
                    onClick={salesTax.onAdd}
                    tabIndex={0}
                    data-testid={suffixify(testId, "add-sales-tax")}
                  >
                    <Icon name="plus" />
                  </Tooltip>
                ) : (
                  <Tooltip
                    as={Button}
                    size="sm"
                    color="neutral"
                    onClick={salesTax.onRemove}
                    variant="text"
                    message="Remove sales tax"
                    tabIndex={0}
                    data-testid={suffixify(testId, "remove-sales-tax")}
                  >
                    <Icon name="trash" />
                  </Tooltip>
                )}
              </>
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
});

InfoFooter.displayName = "InfoFooter";

const COLUMNS: TableColumn<Data>[] = [
  {
    id: "info",
    width: "fill",
    render: (row, index) => <InfoRender index={index} {...row} />,
    footer: () => <InfoFooter />,
  },
];

const BREAKPOINTS: Record<string, number> = {
  mobile: 0, // 0 to 599
  tablet: 650, // 650 and beyond
};

export const Items = memo(
  ({
    id,
    data,
    onAdd,
    total,
    disabled = false,
    onChange,
    onRemove,
    salesTax,
    isNewItem,
    "data-testid": testId,
    componentsProps,
    actions,
  }: ItemsProps) => {
    const tableRef = useRef<TableRef>(null);

    const itemsLength = data.length;

    const previousItemLength = usePrevious(itemsLength, 0);

    const [selected, setSelected] = useState<Data[]>([]);

    const [autoFocus, setAutoFocus] = useState(false);

    const removable = itemsLength > 1;

    const costCodesAccounts = useCostCodesAccountsSimplified({
      accountFilters: componentsProps?.costCodeAccount?.accountFilters,
      costCodeFilters: componentsProps?.costCodeAccount?.costCodeFilters,
    });

    const isActionsVisible = selected.length > 0;

    const enhancedDisable = useMemo<EnhancedDisabled>(
      () => ({
        fields: is.boolean(disabled) ? disabled : (disabled.fields ?? false),
        addLine: is.boolean(disabled) ? disabled : (disabled.addLine ?? false),
        salesTax: is.boolean(disabled)
          ? disabled
          : (disabled.salesTax ?? false),
        removeLine: is.boolean(disabled)
          ? disabled
          : (disabled.removeLine ?? false),
        batchRemove: is.boolean(disabled)
          ? disabled
          : (disabled.batchRemove ?? false),
        batchUpdate: is.boolean(disabled)
          ? disabled
          : (disabled.batchUpdate ?? false),
      }),
      [disabled]
    );

    const onCostCodeAccountChange = useEvent((_, option?: Option) => {
      if (!option) return;

      const { success, error } = selected.reduce(
        (acc, item) => {
          const result = onChange({
            id: item.id,
            name: "costCodeAccount",
            value: option,
          });

          return {
            success: result !== false ? acc.success + 1 : acc.success,
            error: result === false ? acc.error + 1 : acc.error,
          };
        },
        { success: 0, error: 0 }
      );

      const group = option.groupLabel ?? costCodesAccounts.data[0].groupLabel!;

      if (success) {
        toast.success(
          `${group} (${option.label}) was applied to ${success} selected item${success > 1 ? "s" : ""}`
        );
      }

      if (error) {
        toast.error(
          `${group} (${option.label}) was not applied to ${error} selected item${error > 1 ? "s" : ""}`
        );
      }
    });

    const onJobCustomerChange = useEvent((_, option?: Option) => {
      if (!option) return;

      const { success, error } = selected.reduce(
        (acc, item) => {
          const result = onChange({
            id: item.id,
            name: "jobCustomer",
            value: option,
          });

          return {
            success: result !== false ? acc.success + 1 : acc.success,
            error: result === false ? acc.error + 1 : acc.error,
          };
        },
        { success: 0, error: 0 }
      );

      if (success) {
        toast.success(
          `Job (${option.label}) was applied to ${success} selected item${success > 1 ? "s" : ""}`
        );
      }

      if (error) {
        toast.error(
          `Job (${option.label}) was not applied to ${error} selected item${error > 1 ? "s" : ""}`
        );
      }
    });

    const enhancedOnAdd = useEvent((autoFocus = true) => {
      onAdd?.();

      if (!autoFocus) return;

      setAutoFocus(true);
      tableRef.current?.scrollToIndex(data.length);
    });

    const onBulkRemoveClick = useEvent(() => {
      const { success, error } = selected.reduce(
        (acc, item) => {
          const result = onRemove(item.id);

          return {
            success: result !== false ? acc.success + 1 : acc.success,
            error: result === false ? acc.error + 1 : acc.error,
          };
        },
        { success: 0, error: 0 }
      );

      if (success === itemsLength) enhancedOnAdd(false);

      if (success) {
        toast.success(
          `${success} selected item${success > 1 ? "s" : ""} removed`
        );
      }

      if (error) {
        toast.error(
          `${error} selected item${error > 1 ? "s" : ""} could not be removed`
        );
      }
    });

    const header = useMemo<TableHeaderAddon>(
      () => ({
        hide:
          enhancedDisable.batchRemove &&
          enhancedDisable.batchUpdate &&
          enhancedDisable.addLine,
        render() {
          const addLineButton = !enhancedDisable.addLine && (
            <AddLineButton
              onClick={enhancedOnAdd}
              data-testid={suffixify(testId, "add")}
            />
          );

          return (
            <Flex gap="xl" height="3xl" align="center">
              {isActionsVisible ? (
                <>
                  <Dropdown>
                    <DropdownTrigger
                      data-testid={suffixify(
                        testId,
                        "batch-job-customer-trigger"
                      )}
                      animated
                      style={{ overflow: "hidden", minWidth: "66px" }}
                    >
                      <Text as="span" truncate weight="bold">
                        Set job
                      </Text>
                    </DropdownTrigger>
                    <DropdownList>
                      <CustomersComboBox
                        as={DropdownComboBoxItem}
                        label=""
                        value=""
                        onChange={onJobCustomerChange}
                        data-testid={suffixify(testId, "batch-job-customer")}
                      />
                    </DropdownList>
                  </Dropdown>
                  <Dropdown>
                    <DropdownTrigger
                      data-testid={suffixify(
                        testId,
                        "batch-cost-code-account-trigger"
                      )}
                      animated
                      style={{ overflow: "hidden" }}
                    >
                      <Text as="span" truncate weight="bold">
                        Set{` `}
                        {renderCostCodeAccountPlaceholder({
                          data: costCodesAccounts.data,
                          transform: "lowercase",
                        })}
                      </Text>
                    </DropdownTrigger>
                    <DropdownList>
                      <CostCodeAccountComboBox
                        as={DropdownComboBoxItem}
                        label=""
                        value=""
                        onChange={onCostCodeAccountChange}
                        data-testid={suffixify(
                          testId,
                          "batch-cost-code-account"
                        )}
                        accountFilters={
                          componentsProps?.costCodeAccount?.accountFilters
                        }
                        costCodeFilters={
                          componentsProps?.costCodeAccount?.costCodeFilters
                        }
                      />
                    </DropdownList>
                  </Dropdown>
                  <Flex gap="md" shrink={false}>
                    {removable && !enhancedDisable.batchRemove && (
                      <Tooltip
                        as={Button}
                        size="sm"
                        color="neutral"
                        variant="ghost"
                        onClick={onBulkRemoveClick}
                        message="Remove lines"
                        tabIndex={0}
                        data-testid={suffixify(testId, "batch-remove")}
                      >
                        <Icon name="trash" />
                      </Tooltip>
                    )}
                    {actions}
                    {addLineButton}
                  </Flex>
                </>
              ) : (
                <Flex gap="md">
                  {actions}
                  {addLineButton}
                </Flex>
              )}
            </Flex>
          );
        },
        sticky: componentsProps?.table?.headerSticky,
      }),
      [
        enhancedDisable.batchRemove,
        enhancedDisable.batchUpdate,
        enhancedDisable.addLine,
        componentsProps?.table?.headerSticky,
        componentsProps?.costCodeAccount?.accountFilters,
        componentsProps?.costCodeAccount?.costCodeFilters,
        enhancedOnAdd,
        testId,
        isActionsVisible,
        onJobCustomerChange,
        costCodesAccounts.data,
        onCostCodeAccountChange,
        removable,
        onBulkRemoveClick,
        actions,
      ]
    );

    const isFocusableItem = useCallback<IsFocusableItemHandler>(
      (item) => item.index === data.length - 1 && isNewItem(item),
      [data.length, isNewItem]
    );

    const select = useMemo<TableSelectAddon<Data>>(
      () =>
        (enhancedDisable.batchUpdate && enhancedDisable.batchRemove) ||
        itemsLength === 0
          ? {}
          : {
              value: selected,
              offset: { y: 6 },
              onChange: setSelected,
              placement: "top",
            },
      [
        enhancedDisable.batchRemove,
        enhancedDisable.batchUpdate,
        itemsLength,
        selected,
      ]
    );

    const footer = useMemo<TableFooterAddon>(
      () => ({ hide: itemsLength === 0, sticky: false }),
      [itemsLength]
    );

    const emptyState = useMemo<TableEmptyState>(
      () => ({
        title: "No lines have been added to this transaction",
        action: (
          <Button
            onClick={enhancedOnAdd}
            data-testid={suffixify(testId, "add")}
          >
            <Icon name="plus" />
            Add line
          </Button>
        ),
      }),
      [enhancedOnAdd, testId]
    );

    useEffect(() => {
      if (itemsLength !== previousItemLength) {
        requestAnimationFrame(() => setSelected([]));
      }
    }, [itemsLength, previousItemLength]);

    return (
      <ResponsiveProvider
        breakpoints={BREAKPOINTS}
        containerRef={tableRef}
        initialBreakpoint="tablet"
      >
        <Flex gap="xl" direction="column">
          <Text size="xl" weight="bold">
            Line items
          </Text>
          <ItemsContext.Provider
            value={{
              total,
              testId,
              disabled: enhancedDisable,
              onChange,
              onRemove,
              salesTax,
              removable,
              autoFocus,
              itemsLength,
              setAutoFocus,
              componentsProps,
              isFocusableItem,
            }}
          >
            <Table
              id={id}
              ref={tableRef}
              size="sm"
              data={data}
              select={select}
              header={header}
              footer={footer}
              columns={COLUMNS}
              emptyState={emptyState}
              data-testid={testId}
            />
          </ItemsContext.Provider>
        </Flex>
      </ResponsiveProvider>
    );
  }
);

Items.displayName = "Items";
