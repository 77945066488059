import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  Link,
  Loader,
  Table,
  type TableColumn,
  type TableRowAddon,
  type TableSelectAddon,
  Tag,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useEvent, usePagination } from "@adaptive/design-system/hooks";
import {
  formatCurrency,
  formatDate,
  parseDate,
} from "@adaptive/design-system/utils";
import { useGetInvoicesQuery } from "@api/invoices";
import { invoicesApi } from "@api/invoices";
import {
  type DrawnToDate,
  useGetDrawnToDateQuery,
  useLazyGetLinkedInvoicesQuery,
} from "@api/jobs";
import {
  BatchApplyObject,
  type BatchApplyObjectOnChangeHandlerProps,
} from "@components/batch-apply-object";
import {
  DownloadButton,
  type OnDownloadHandler,
} from "@components/download-button";
import {
  type StrictValuesFilters,
  TableFilterControls,
} from "@components/table-filter";
import {
  defaultArrayFormatter,
  type QueryItem,
} from "@components/table-filter/formatters";
import { useTableFilters } from "@components/table-filter/table-filter-hooks";
import { useApplyObject } from "@hooks/use-apply-object";
import { type Option } from "@shared/types";
import { HUMAN_READABLE_BILL_STATUS } from "@src/bills/constants";
import {
  HUMAN_READABLE_EXPENSE_REVIEW_STATUS,
  HUMAN_READABLE_EXPENSE_TYPE,
} from "@src/expenses/utils/constants";
import { api as reduxApi } from "@store/api-simplified";
import { useJobInfo } from "@store/jobs";
import { useClientInfo } from "@store/user";
import * as analytics from "@utils/analytics";
import { api } from "@utils/api";
import { noop } from "@utils/noop";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import type { InvoiceData } from "@utils/transaction-confirm-messages";
import { confirmSyncLinkedCost } from "@utils/transaction-confirm-messages/confirm-sync-linked-cost";

import type { DrawnToDateDialogProps } from ".";

const PER_PAGE = 10;

const TYPE_TO_URL = {
  Bill: "bills",
  Receipt: "expenses",
  Invoice: "invoices",
  "Vendor Credit": "bills",
};

const DRAW_STATUS = [
  { status: "DRAFT", label: "Unsynced", variant: "neutral" },
  { status: "FOR_APPROVAL", label: "Unsynced", variant: "neutral" },
  { status: "APPROVED", label: "Unsynced", variant: "neutral" },
  { status: "ERROR", label: "Sync Error", variant: "error" },
  { status: "IGNORED_ERROR", label: "Sync Error", variant: "error" },
  { status: "SYNCED", label: "Synced", variant: "success" },
  { status: "PAID", label: "Paid", variant: "success" },
] as const;

const TRANSACTION_TYPE_FILTER = (
  [
    { value: "bill", label: "Bill" },
    { value: "receipt", label: "Receipt" },
    { value: "labor", label: "Labor" },
    { value: "vendor credit", label: "Vendor credit" },
    { value: "empty", label: "Empty line" },
  ] as const
).map((filter) => ({ ...filter, groupLabel: "Transaction type" }));

const EXTRA_DATA = [...TRANSACTION_TYPE_FILTER];

const hasExtraFilter = (
  filters: QueryItem[],
  expect: (typeof EXTRA_DATA)[number]["value"]
) => {
  return filters.some((filter) => filter.value === expect);
};

type TransactionLine = { id: string; url: string };

const INITIAL_FILTERS = {} as StrictValuesFilters;

const EMPTY_INVOICES: InvoiceData[] = [];

const EMPTY_DRAW_TO_DATE: DrawnToDate[] = [];

export const DrawnToDateDialog = ({
  dialog,
  budgetLine,
}: DrawnToDateDialogProps) => {
  const { job } = useJobInfo();

  const pagination = usePagination();

  const [offset, setOffset] = useState<number>(0);

  const [selected, setSelected] = useState<DrawnToDate[]>([]);

  const { client } = useClientInfo();

  const emptyLinesSelected = useMemo(
    () => selected.some((line) => !line.transactionLine),
    [selected]
  );

  const applyObject = useApplyObject({
    items: selected,
    resource: "invoicelines",
  });

  const previousBatchApplyToast = useRef(noop);

  const {
    setFilters,
    filters = [],
    rawFilters,
  } = useTableFilters({
    formatter: defaultArrayFormatter,
    initialFilters: INITIAL_FILTERS,
  });
  const {
    data = { results: EMPTY_DRAW_TO_DATE, count: 0 },
    isLoading: drawnToDateLoading,
    refetch: drawnToDateRefetch,
  } = useGetDrawnToDateQuery({
    customerId: job.id.toString(),
    filters: [
      ...filters,
      {
        dataIndex: "accounts",
        value: budgetLine?.jobCostMethod?.url.includes("account")
          ? budgetLine?.jobCostMethod?.id
          : "",
      },
      {
        dataIndex: "items",
        value: budgetLine?.jobCostMethod?.url.includes("item")
          ? budgetLine?.jobCostMethod?.id
          : "",
      },
      {
        dataIndex: "offset",
        value: offset,
      },
      {
        dataIndex: "limit",
        value: PER_PAGE,
      },
    ],
  });

  const { data: invoices = { results: EMPTY_INVOICES, count: 0 } } =
    useGetInvoicesQuery({
      customerId: job.id,
    });

  const drawsFilter = useMemo(() => {
    if (invoices) {
      return invoices.results.reduce((acc, item) => {
        const exist = acc.find((draw) => draw.value === item.id);
        if (!exist) {
          acc.push({
            value: item.id,
            label: `${item.docNumber}`,
            groupLabel: "Draw #",
          });
        }
        return acc;
      }, [] as Option[]);
    }

    return [];
  }, [invoices]);

  const getTransactionUrl = useCallback((row: DrawnToDate) => {
    if (!row.transactionLine?.parent) return;

    if (row.transactionLine.parent.humanReadableType in TYPE_TO_URL) {
      const path =
        TYPE_TO_URL[
          row.transactionLine.parent
            .humanReadableType as keyof typeof TYPE_TO_URL
        ];
      return `/${path}/${row.transactionLine.parent.id}`;
    }
    return "";
  }, []);

  const onClearFilters = useEvent(() => setFilters({}));
  const dispatch = useDispatch();

  const onFiltersChange = useEvent((filters) => setFilters(filters));

  const hasFilters = Object.values(rawFilters).some((filter) => !!filter);
  const [triggerGetLinkedInvoices] = useLazyGetLinkedInvoicesQuery();

  const searchResults = useMemo(() => {
    const items: DrawnToDate[] = [];

    if (!hasFilters) return data.results as DrawnToDate[];

    for (const item of data.results) {
      const hasDrawNumberFilter = filters.some(
        (filter) => filter.dataIndex == "draw_#"
      );
      const belongsToDraw = filters.some(
        (filter) =>
          filter.dataIndex == "draw_#" && filter.value === item.invoice.id
      );
      const hasBillFilter = hasExtraFilter(filters, "bill");
      const hasReceiptFilter = hasExtraFilter(filters, "receipt");
      const hasLaborFilter = hasExtraFilter(filters, "labor");
      const hasVendorCreditFilter = hasExtraFilter(filters, "vendor credit");
      const hasEmptyLineFilter = hasExtraFilter(filters, "empty");
      const hasAnyTransactionTypeFilter =
        hasBillFilter ||
        hasReceiptFilter ||
        hasLaborFilter ||
        hasVendorCreditFilter ||
        hasEmptyLineFilter;

      const belongsToTransactionType =
        (hasExtraFilter(filters, "bill") &&
          item.transactionLine?.parent?.humanReadableType === "Bill") ||
        (hasExtraFilter(filters, "receipt") &&
          item.transactionLine?.parent?.humanReadableType === "Receipt") ||
        (hasExtraFilter(filters, "labor") &&
          item.transactionLine?.parent?.humanReadableType === "Labor") ||
        (hasExtraFilter(filters, "vendor credit") &&
          item.transactionLine?.parent?.humanReadableType ===
            "Vendor Credit") ||
        (hasExtraFilter(filters, "empty") && !item.transactionLine);

      if (
        (hasAnyTransactionTypeFilter && !belongsToTransactionType) ||
        (hasDrawNumberFilter && !belongsToDraw)
      )
        continue;
      else items.push({ ...item });
    }
    return items;
  }, [data, hasFilters, filters]);

  const row = useMemo<TableRowAddon<DrawnToDate>>(
    () => ({
      isVisible: (row) => searchResults.some((item) => item.id === row.id),
    }),
    [searchResults]
  );

  const select = useMemo<TableSelectAddon<DrawnToDate>>(
    () => ({
      value: selected,
      onChange: setSelected,
    }),
    [selected]
  );

  const columns = useMemo<TableColumn<DrawnToDate>[]>(() => {
    const dynamicColumns: TableColumn<DrawnToDate>[] = [
      {
        id: "vendor",
        width: "fill",
        name: "Vendor / Employee",
        render: (row) => row.vendor?.displayName || client?.name,
      },
    ];

    if (!budgetLine?.jobCostMethod) {
      dynamicColumns.push({
        id: "item",
        name: "Cost code / Account",
        render: (row) => row.jobCostMethod?.displayName,
      });
    }

    return dynamicColumns.concat([
      {
        id: "status",
        name: "Status",
        width: 150,
        render: (row) => {
          if (!row.transactionLine || !row.transactionLine?.parent) return "—";

          if (
            row.transactionLine.parent.humanReadableType === "Bill" ||
            row.transactionLine.parent.humanReadableType === "Vendor Credit"
          ) {
            return HUMAN_READABLE_BILL_STATUS[
              row.transactionLine.parent
                .reviewStatus as keyof typeof HUMAN_READABLE_BILL_STATUS
            ];
          } else {
            return HUMAN_READABLE_EXPENSE_REVIEW_STATUS[
              row.transactionLine.parent
                .reviewStatus as keyof typeof HUMAN_READABLE_EXPENSE_REVIEW_STATUS
            ];
          }
        },
      },
      {
        id: "type",
        name: "Type",
        render: (row) => {
          if (!row.transactionLine || !row.transactionLine?.parent) return "—";
          return row.transactionLine.parent.humanReadableType;
        },
      },
      {
        id: "date",
        name: "Date",
        width: 100,
        render: (row) => {
          if (!row.transactionLine || !row.transactionLine?.parent) return "—";
          return formatDate(
            parseDate(row.transactionLine.parent.date, "yyyy-MM-dd")
          );
        },
      },
      {
        id: "amount",
        name: "Cost",
        width: 120,
        textAlign: "right",
        render: (row) => {
          if (!row.transactionLine) return "—";

          return (
            <Flex align="center" gap="sm" justify="flex-end">
              <Text align="right">
                {formatCurrency(row.transactionLine.amount, {
                  currencySign: true,
                  allowNegative: true,
                })}
              </Text>
              {row.transactionLine?.parent?.humanReadableType ===
                HUMAN_READABLE_EXPENSE_TYPE.LABOR &&
                !row.amount && (
                  <Tooltip
                    name="info-circle"
                    as={Icon}
                    size="sm"
                    message="Hourly rate is not set in QuickBooks"
                  />
                )}
            </Flex>
          );
        },
      },
      {
        id: "price",
        name: "Price",
        width: 120,
        textAlign: "left",
        render: (row) =>
          formatCurrency(row.amount, {
            currencySign: true,
            allowNegative: true,
          }),
      },
      {
        id: "number",
        name: "Ref #",
        width: 100,
        render: (row) => {
          const transactionUrl = getTransactionUrl(row);

          if (
            !row.transactionLine ||
            !row.transactionLine?.parent ||
            !transactionUrl
          )
            return "—";

          return row.transactionLine.parent.docNumber &&
            row.transactionLine.parent.humanReadableType ===
              HUMAN_READABLE_EXPENSE_TYPE.LABOR ? (
            <Flex align="center">
              <Text>{`#${row.transactionLine.parent.docNumber}`}</Text>
            </Flex>
          ) : (
            <Link
              rel="noreferrer"
              href={transactionUrl}
              target="_blank"
              variant="success"
            >
              <Text as="span" size="sm">
                {`#${row.transactionLine.parent.docNumber}`}
              </Text>
            </Link>
          );
        },
      },
      {
        id: "draw",
        name: "Draw #",
        render: (row) => (
          <Link
            rel="noreferrer"
            href={`${job.id}/invoices/${row.invoice.id}?status=line-items`}
            target="_blank"
            variant="success"
          >
            <Text as="span" size="sm">{`#${row.invoice.docNumber}`}</Text>
          </Link>
        ),
      },
      {
        id: "draw_status",
        name: "Draw status",
        render: (row) => {
          let status = null;
          if (row.invoice.errors.length) {
            const hasNotIgnoredErrors = row.invoice.errors.some(
              (error) => !error.isIgnored
            );
            status = DRAW_STATUS.find(
              (item) =>
                (item.status == "ERROR" && hasNotIgnoredErrors) ||
                (item.status == "IGNORED_ERROR" && !hasNotIgnoredErrors)
            );
          } else if (
            row.invoice.errors.length == 0 &&
            row.invoice.publishedToQuickbooks
          ) {
            status = DRAW_STATUS.find((item) => item.status == "SYNCED");
          } else {
            status = DRAW_STATUS.find(
              (item) => item.status == row.invoice.reviewStatus
            );
          }
          return <Tag color={status?.variant}>{status?.label}</Tag>;
        },
      },
    ]);
  }, [budgetLine?.jobCostMethod, client?.name, getTransactionUrl, job.id]);

  const onDownload = useCallback<OnDownloadHandler>(
    async ({ params }) => {
      const itemsUrl = budgetLine
        ? `/${
            budgetLine.sourceType === "Cost Code" ? "items" : "accounts"
          }/${budgetLine?.jobCostMethod?.id}`
        : "";
      return api
        .get(`/api/customers/${job.id}${itemsUrl}/lines/export/`, {
          params,
        })
        .then(({ data }) => (data?.id ? (data.id as string) : undefined));
    },
    [budgetLine, job.id]
  );

  const onBatchApply = useEvent(
    async ({
      items = selected,
      option,
      fieldName,
    }: BatchApplyObjectOnChangeHandlerProps & {
      items?: TransactionLine[];
    }) => {
      if (!option) return;

      const handler = async (syncTransactionLines = false) => {
        try {
          const { success } = await applyObject.execute({
            items,
            value: option.value,
            method: "apply_object_batch",
            fieldName,
            syncTransactionLines,
          });

          await drawnToDateRefetch();

          dispatch(
            reduxApi.util.invalidateTags(["BudgetLines", "CustomerMarkup"])
          );

          dispatch(
            invoicesApi.util.invalidateTags([
              "Invoices",
              "InvoiceLines",
              "InvoiceMarkups",
            ])
          );

          analytics.track("budgetDrawnToDateBatchActions", {
            value: option.value,
            action: fieldName,
            transactionIds: items.map((transaction) => transaction.id),
          });

          if (fieldName === "customer") {
            if (job.url === option.value) {
              return toast.success(
                <Flex as="span" direction="column">
                  <Text as="strong" weight="bold">
                    Transaction{success > 1 ? "s" : ""} moved back to{" "}
                    {job.display_name}
                  </Text>
                  <Text as="span">Go to the Draws tab to re-add</Text>
                </Flex>
              );
            }

            const undo = () => {
              previousBatchApplyToast.current();

              onBatchApply({
                value: job.url,
                items,
                option: { label: job.display_name, value: job.url },
                fieldName: "customer",
              });
            };

            const { dismiss } = toast.success(
              <Flex as="span" direction="column">
                <Text as="strong" weight="bold">
                  Transaction{success > 1 ? "s" : ""} moved to{" "}
                  {option?.label ?? "Unknown"}
                </Text>
                <Flex gap="lg">
                  <Link as="button" type="button" onClick={undo}>
                    Undo
                  </Link>
                  <Link
                    rel="noreferrer"
                    href={`/jobs/${parseRefinementIdFromUrl(option.value)}`}
                    target="_blank"
                  >
                    View job
                  </Link>
                </Flex>
              </Flex>,
              { duration: 10000 }
            );

            previousBatchApplyToast.current = dismiss;
          } else {
            toast.success(`${success} Line${success > 1 ? "s" : ""} updated!`);
          }
        } catch (e) {
          toast.error(`Error updating lines`);
        }
      };

      const { data } = await triggerGetLinkedInvoices({
        billIds: selected
          .filter(
            (item) =>
              item.transactionLine?.parent &&
              item.transactionLine.parent.humanReadableType === "Bill"
          )
          .map(({ transactionLine }) => `${transactionLine?.parent?.id}`),

        expenseIds: selected
          .filter(
            (item) =>
              item.transactionLine?.parent &&
              item.transactionLine.parent.humanReadableType === "Receipt"
          )
          .map(({ transactionLine }) => `${transactionLine?.parent?.id}`),
      });
      const linkedInvoices: InvoiceData[] = data?.invoices ?? [];
      if (linkedInvoices.length) {
        confirmSyncLinkedCost({
          action: {
            primary: {
              color: "primary",
              onClick: () => handler(true),
              children: "Yes, update cost",
            },
            secondary: {
              onClick: handler,
              children: "No, don't update cost",
            },
          },
        });
      } else {
        handler();
      }
    }
  );

  useEffect(() => {
    setOffset(pagination.offset);
  }, [pagination.offset]);

  useEffect(() => {
    if (!dialog.isVisible) pagination.setPage(0);
  }, [dialog.isVisible, pagination]);

  return (
    <>
      {applyObject.isLoading && <Loader position="fixed" />}
      <Dialog
        size="auto"
        variant="dialog"
        show={dialog.isVisible}
        onClose={dialog.hide}
      >
        <DialogHeader>
          <Flex direction="column" gap="md">
            {budgetLine?.jobCostMethod?.displayName ?? "Drawn to date"}
            <Text size="md">Invoice lines</Text>
          </Flex>
        </DialogHeader>
        <DialogContent>
          <Flex direction="column" gap="lg" minWidth="900px">
            <Flex gap="md" direction="column">
              <TableFilterControls
                filters={rawFilters}
                extraData={[...EXTRA_DATA, ...drawsFilter]}
                withFilterTags
                withDateFilter
                includeFilters={[]}
                dateProps={{
                  grow: true,
                  maxWidth: "auto",
                }}
                filterProps={{
                  grow: true,
                  maxWidth: "auto",
                }}
                onFiltersChange={onFiltersChange}
                renderAfter={() => (
                  <Flex width="400px" gap="md" shrink={false}>
                    {hasFilters && (
                      <Button onClick={onClearFilters}>Clear filters</Button>
                    )}
                    <Flex gap="md" width="full" justify="flex-end">
                      {data.results.length ? (
                        <DownloadButton
                          mode={{
                            all: { enabled: true, children: "Download" },
                            selection: { enabled: false },
                          }}
                          size="md"
                          withDate
                          onDownload={onDownload}
                          data-testid="budget"
                        />
                      ) : null}
                      {selected.length > 0 && (
                        <Dropdown placement="bottom-end">
                          <DropdownTrigger
                            as={Button}
                            color="primary"
                            data-testid="drawn-to-date-actions-trigger"
                          >
                            Actions
                            <Icon name="ellipsis-vertical" variant="solid" />
                          </DropdownTrigger>
                          <DropdownList>
                            <DropdownItem>
                              <BatchApplyObject
                                onChange={onBatchApply}
                                includeVendors={false}
                                includeJobs={!emptyLinesSelected}
                                accountFilters={{
                                  only_line_item_accounts: true,
                                }}
                              />
                            </DropdownItem>
                          </DropdownList>
                        </Dropdown>
                      )}
                    </Flex>
                  </Flex>
                )}
              />
            </Flex>
            <Table
              id="drawn-to-date-dialog-table"
              size="sm"
              row={row}
              loading={drawnToDateLoading}
              data={data.results}
              select={select}
              header={{ hide: data.results.length === 0 }}
              columns={columns}
              maxHeight="500px"
              pagination={{
                page: pagination.page,
                total: data?.count ?? 0,
                perPage: pagination.perPage,
                onChange: pagination.setPage,
              }}
              data-testid="drawn-to-date-table"
            />
          </Flex>
        </DialogContent>
      </Dialog>
    </>
  );
};
