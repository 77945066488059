import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Flex, Icon, Tooltip } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { isEqual } from "@adaptive/design-system/utils";
import { Items as LineItems, type ItemsProps } from "@components/items";
import {
  selectExpenseCalculations,
  useExpenseLines,
  useExpensePermissions,
  useTax,
} from "@store/expenses";
import { expenseSelectors } from "@store/expenses/selectors";
import { useModalVisibility } from "@store/ui";
import { noop } from "@utils/noop";

import { STRINGS } from "../stages/constants";

export const Items = () => {
  const isArchived = useSelector(expenseSelectors.isArchived, isEqual);

  const { setTax } = useTax();

  const reviewStatus = useSelector(expenseSelectors.reviewStatus, isEqual);

  const calculations = useSelector(selectExpenseCalculations, isEqual);

  const { setVisible } = useModalVisibility("SalesTax");

  const { canEditExpense } = useExpensePermissions();

  const {
    lines,
    setJob,
    setAmount,
    addNewLine,
    setBillable,
    setDescription,
    removeLineById,
    setCostAttribution,
  } = useExpenseLines();

  const isDraft = reviewStatus === "DRAFT";

  const isEditable = !isArchived && canEditExpense;

  const data = useMemo<ItemsProps["data"]>(
    () =>
      Object.values(lines).map((line) => {
        const amountValue = line.amount ?? 0;

        /**
         * @todo fix typescript issue with this format for some reason
         * it not working even though it has the correct types
         */
        const costCodeAccountValue: any =
          line.attribution?.url && line.attribution?.displayName
            ? {
                label: line.attribution.displayName,
                value: line.attribution.url,
                groupLabel: line.attribution.groupLabel,
              }
            : line.attribution?.url
              ? line.attribution.url
              : "";

        const isCostCodeAccountRequired = !isDraft;
        const isBillable = ["Billable", "HasBeenBilled"].includes(
          line.billableStatus ?? ""
        );
        /**
         * @todo fix typescript issue with this format for some reason
         * it not working even though it has the correct types
         */
        const jobCustomerValue: any =
          line.customer?.url && line.customer?.displayName
            ? { label: line.customer.displayName, value: line.customer.url }
            : line.customer?.url
              ? line.customer?.url
              : "";

        const isJobCustomerRequired =
          !isDraft &&
          (line.attribution?.groupLabel === "Cost code" || isBillable);

        const hasRemainingBudget = !!jobCustomerValue && !!costCodeAccountValue;

        return {
          id: line.id,
          amount: {
            value: amountValue,
            required: true,
            errorMessage: amountValue === 0 ? STRINGS.ERROR_LINE_AMOUNT : "",
          },
          billable: { checked: isBillable },
          jobCustomer: {
            value: jobCustomerValue,
            required: isJobCustomerRequired,
            errorMessage:
              !jobCustomerValue && isJobCustomerRequired
                ? STRINGS.ERROR_JOB
                : "",
          },
          ...(hasRemainingBudget
            ? {
                extra: {
                  label: "Remaining budget",
                  value: line.remainingBudgetAfterThisExpenseBlocked ? (
                    <Flex as="span" align="center" gap="sm">
                      Save{isDraft ? " draft" : ""}
                      <Tooltip
                        as={Icon}
                        size="sm"
                        name="info-circle"
                        color="neutral-800"
                        message={`Click "Save${
                          isDraft ? " draft" : ""
                        }" below to update the Remaining budget`}
                      />
                    </Flex>
                  ) : line.remainingBudgetAfterThisExpense === null ? (
                    "Budget not set"
                  ) : (
                    line.remainingBudgetAfterThisExpense
                  ),
                  variant:
                    line.remainingBudgetAfterThisExpense === null ||
                    line.remainingBudgetAfterThisExpense! < 0 ||
                    line.remainingBudgetAfterThisExpenseBlocked
                      ? "warning"
                      : "neutral",
                  onClick: line.remainingBudgetAfterThisExpenseBlocked
                    ? undefined
                    : () => {
                        window
                          .open(`/jobs/${line.customer?.id}`, "_blank")
                          ?.focus();
                      },
                },
              }
            : {}),
          description: { value: line.description || "" },
          costCodeAccount: {
            value: costCodeAccountValue,
            required: isCostCodeAccountRequired,
            errorMessage:
              !costCodeAccountValue && isCostCodeAccountRequired
                ? STRINGS.ERROR_CC_ACCT
                : "",
          },
        };
      }),
    [lines, isDraft]
  );

  const mutateStrategy = {
    amount: setAmount,
    billable: setBillable,
    closable: noop,
    jobCustomer: setJob,
    description: setDescription,
    costCodeAccount: setCostAttribution,
  };

  const componentsProps = useMemo<ItemsProps["componentsProps"]>(
    () => ({
      costCodeAccount: {
        accountFilters: { only_line_item_accounts: true },
      },
    }),
    []
  );

  const onChange = useEvent<ItemsProps["onChange"]>(({ id, name, value }) => {
    mutateStrategy[name](id, value as never);
  });

  const onAddSalesTax = useEvent(() => setVisible(true));

  const onRemoveSalesTax = useEvent(() => setTax(0));

  const salesTax = useMemo(
    () => ({
      onAdd: onAddSalesTax,
      amount: calculations.salesTax,
      subTotal: calculations.subTotal,
      onRemove: onRemoveSalesTax,
    }),
    [
      onAddSalesTax,
      onRemoveSalesTax,
      calculations.salesTax,
      calculations.subTotal,
    ]
  );

  const isNewItem = useCallback<ItemsProps["isNewItem"]>(
    (item) => typeof item.id === "number" && item.id < 1,
    []
  );

  return (
    <LineItems
      id="expense-items"
      data={data}
      total={calculations.total}
      onAdd={addNewLine}
      disabled={!isEditable}
      onChange={onChange}
      onRemove={removeLineById}
      salesTax={salesTax}
      isNewItem={isNewItem}
      data-testid="items"
      componentsProps={componentsProps}
    />
  );
};
