import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertContent,
  Button,
  Flex,
  LabelValue,
  TextField,
} from "@adaptive/design-system";
import { useDialog, useForm } from "@adaptive/design-system/hooks";
import { postVendorPublicAchRequest } from "@api/vendor-requests";
import { ConfirmationDialog } from "@components/confirmation-dialog";
import { Layout } from "@components/layout";
import { z } from "zod";

import { RequestFormExpired, RequestFormSubmitted } from "./common";
import { STRINGS } from "./constants";
import { AdaptiveFooter } from "./footers";
import { type FormProps } from "./types";
import { VendorRequest } from "./vendor-request";

const schema = z
  .object({
    accountNumber: z.string().min(4, STRINGS.INVALID_ACCOUNT_NUMBER_ERROR),
    routingNumber: z.string().length(9, STRINGS.INVALID_ROUTING_NUMBER_ERROR),
    confirm: z.string().min(4, STRINGS.CONFIRM_ACCOUNT_NUMBER_ERROR),
  })
  .refine((values) => values.accountNumber === values.confirm, {
    message: STRINGS.ACCOUNT_NUMBERS_DONT_MATCH_ERROR,
    path: ["confirm"],
  });

type Fields = z.infer<typeof schema>;
const initialValues: Fields = {
  accountNumber: "",
  routingNumber: "",
  confirm: "",
};

export const VendorACHRequest = () => {
  return <VendorRequest type="ACH" form={ACHRequestForm} />;
};

export const ACHRequestForm = ({
  vendor,
  companyName,
  queryParams,
}: FormProps) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [isFormSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [requestExpired, setRequestExpired] = useState<boolean>(false);
  const [values, setValues] = useState<Fields>(initialValues);
  const formSubmitDialog = useDialog();

  const form = useForm<Fields>({
    schema,
    async onSubmit(values) {
      setValues(values);
      formSubmitDialog.show();
    },
    initialValues,
  });

  useEffect(() => {
    setErrors([]);
  }, [form.values]);

  const formSubmittedMessage = `Thank you for providing your ACH details! ${companyName} has all the information they need to pay you now.`;

  return requestExpired ? (
    <RequestFormExpired companyName={companyName} />
  ) : isFormSubmitted ? (
    <RequestFormSubmitted message={formSubmittedMessage} />
  ) : (
    <Layout
      title="Enter ACH details"
      onSubmit={form.props.onSubmit}
      gap={{ mobile: "3xl", tablet: "96px" }}
      padding={{ mobile: "2xl", tablet: ["5xl", "5xl", "none"] }}
    >
      <Flex
        padding={{
          mobile: ["none", "none", "lg", "none"],
          tablet: ["none", "none", "3xl", "none"],
        }}
      >
        <LabelValue label="Vendor name" value={vendor.display_name} />
      </Flex>
      <TextField
        label={STRINGS.LABEL_ROUTING_NUMBER}
        required
        autoFocus
        {...form.register("routingNumber")}
      />
      <TextField
        label={STRINGS.LABEL_ACCOUNT_NUMBER}
        required
        {...form.register("accountNumber")}
      />
      <TextField
        label={STRINGS.LABEL_CONFIRM_ACCOUNT_NUMBER}
        required
        {...form.register("confirm")}
      />
      <ConfirmationDialog
        title={STRINGS.ACH_CONFIRMATION_DIALOG_TITLE}
        message={STRINGS.ACH_CONFIRMATION_DIALOG_MESSAGE}
        dialog={formSubmitDialog}
        onConfirm={() => {
          formSubmitDialog.hide();
          const data = {
            account_number: values?.accountNumber,
            routing_number: values?.routingNumber,
            vendor: vendor.url,
          };
          return postVendorPublicAchRequest(queryParams, data)
            .then(() => setFormSubmitted(true))
            .catch((response) => {
              const { data } = response.response;
              const newErrors = [];
              if (data.account_number) {
                newErrors.push(data.account_number);
              } else if (data.routing_number) {
                newErrors.push(data.routing_number);
              } else if (data.non_field_errors) {
                data.non_field_errors.map((error: string) => {
                  newErrors.push(error);
                  if (error === STRINGS.REQUEST_EXPIRED)
                    setRequestExpired(true);
                });
              } else {
                newErrors.push(STRINGS.UNKNOWN_ERROR);
              }
              setErrors(newErrors);
            });
        }}
      />

      {errors.length > 0 && (
        <Flex padding={["none", "none", "3xl", "none"]} direction="column">
          <Alert variant="error">
            <AlertContent as="ul">
              {errors.map((error) =>
                Array.isArray(error) ? (
                  error.map((innerError) => (
                    <li key={innerError}>{innerError}</li>
                  ))
                ) : (
                  <li key={error}>{error}</li>
                )
              )}
            </AlertContent>
          </Alert>
        </Flex>
      )}

      <Flex gap="2xl" direction="column">
        <Button
          data-testid="reset-password"
          type="submit"
          disabled={form.isSubmitting || !form.isValid}
          block
        >
          Save
        </Button>
      </Flex>
      <Flex padding={["3xl", "none", "none"]}>
        <AdaptiveFooter companyName={companyName}></AdaptiveFooter>
      </Flex>
    </Layout>
  );
};
